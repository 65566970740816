/*
Template: Dustrix - Construction & Industry HTML Template
Author: Modina Theme
Author URI: https://themeforest.net/user/modinatheme
Description: Construction & Industry HTML Template
Version: 1.0
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

html, body, div, span, img,
ol, ul, li, a, p, h1, h2, h3,
h4, h5, h6, option, legend, label,
table, th, td, tr, article, aside,
caption, figure, footer, header, hgroup,
mark, nav, section, time, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

img {
  max-width: 100%;
}

a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none !important;
  border: 0;
}

input:focus {
  outline: none;
}

input::-moz-focus-inner {
  border: 0;
  display: none;
}

input::-webkit-focus-inner {
  border: 0;
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  color: #666;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  color: #666;
  opacity: 1;
  /* Firefox */
}

::placeholder {
  color: #666;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #666;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #666;
}

table {
  border-collapse: collapse;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

ul, li {
  margin: 0;
  padding: 0;
}

figure {
  margin: 0px;
}

form {
  position: relative;
}

.form-control:focus {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: 0 0 0;
          box-shadow: 0 0 0;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
}

h1:hover a, h2:hover a, h3:hover a, h4:hover a, h5:hover a, h6:hover a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

hr {
  margin: 0;
  padding: 0;
  border-color: #000;
}

ul {
  list-style-type: none;
}

li {
  list-style: none;
  position: relative;
}

a,
button {
  position: relative;
}

a:hover, a:focus,
button:hover,
button:focus {
  outline: 0;
  text-decoration: none;
}

input, textarea, button {
  border: 0px;
}

.container {
  width: 100%;
}

@media (min-width: 1365px) {
  .container {
    max-width: 1320px !important;
  }
}

@media (max-width: 1364px) {
  .container {
    max-width: inherit !important;
    width: inherit;
  }
}

@media (max-width: 1200px) {
  .container {
    width: 100%;
  }
}

/* ------------------------------------------------------------------
    Template All Variables Defined - Value Change with Your's Need
 -------------------------------------------------------------------- */
/* --------------------------------------------
    Template Default Fonts & Fonts Styles
 ---------------------------------------------- */
body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #777;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  margin: 0px;
  font-weight: 600;
  color: #000;
}

h1 {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: -3px;
}

@media (max-width: 991px) {
  h1 {
    font-size: 2.25rem;
    line-height: 3rem;
  }
}

h1.fs-lg {
  font-size: 80px;
  line-height: 115%;
  letter-spacing: -4px;
}

@media (min-width: 767px) and (max-width: 991px) {
  h1.fs-lg {
    font-size: 42px;
  }
}

h2 {
  font-size: 36px;
  line-height: 130%;
}

h3 {
  font-size: 24px;
  line-height: 36px;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.875rem;
}

h5 {
  font-size: 1.125rem;
  line-height: 1.88rem;
}

h6 {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

a {
  text-decoration: none;
  font-weight: 400;
  outline: none !important;
  cursor: pointer;
  font-size: 16px;
  line-height: 28px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #000;
}

p {
  margin: 0px;
}

.coverbg {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover !important;
}

.mx-auto {
  margin: 0 auto;
}

.align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-center:before, .align-center:after {
  content: none;
}

.align-center-h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.align-center-h:before, .align-center-h:after {
  content: none;
}

.align-center-v {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-center-v:before, .align-center-v:after {
  content: none;
}

.align-center-v-b {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-center-v-b:before, .align-center-v-b:after {
  content: none;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.justify-content-center:before, .justify-content-center:after {
  content: none;
}

.eq-height {
  display: -webkit-box;
  display: -moz-flexbox;
  display: -ms-flexbox;
  display: -o-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.eq-height:before, .eq-height:after {
  content: none;
}

/* ----------------------------------
    Template Helps Classes - Styles
 ------------------------------------ */
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white a {
  color: #fff !important;
}

.mlm-30 {
  margin-left: -30px;
}

/* padding left */
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

/* padding right */
.pr-5 {
  padding-right: 5px;
}

.pr-5i {
  padding-right: 5px !important;
}

@media (max-width: 767px) {
  .pr-5i {
    padding-right: 15px !important;
  }
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-150 {
  padding-right: 150px;
}

/* padding top */
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-150 {
  padding-top: 150px;
}

/* padding bottom */
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-150 {
  padding-bottom: 150px;
}

/* margin left */
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-150 {
  margin-left: 150px;
}

/* margin right */
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/* margin top */
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

/* margin bottom */
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mmb10 {
  margin-bottom: -10px;
}

.mtm-30 {
  margin-top: -30px;
}

.mbm-30 {
  margin-bottom: -30px;
}

.mlm-30 {
  margin-left: -30px;
}

.mlm-40 {
  margin-left: -40px;
}

.mlm-50 {
  margin-left: -50px;
}

.mlm-80 {
  margin-left: -80px;
}

.mlm-100 {
  margin-left: -100px;
}

/* background */
.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.bg-center {
  background-position: center;
}

.bg-contain {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  background-position: left;
}

.bg-right {
  background-repeat: no-repeat;
  background-position: right;
}

.bg-left {
  background-repeat: no-repeat;
  background-position: left;
}

.bg-bottom {
  background-repeat: no-repeat;
  background-position: bottom;
}

.bg-top {
  background-repeat: no-repeat;
  background-position: top;
}

.bg-100 {
  background-size: 100% 100%;
}

.mmb-100 {
  margin-bottom: -100px;
}

.mmb-80 {
  margin-bottom: -80px;
}

.lr-9 {
  margin-left: -9px;
  margin-right: -9px;
}

.lr-9 [class*="col"] {
  padding-left: 9px;
  padding-right: 9px;
}

.bg-overlay {
  position: relative;
  z-index: 1;
}

.bg-overlay::before {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .8;
  background-color: #000;
  z-index: -1;
}

.fix {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.shape {
  position: absolute;
}

@media (max-width: 1191px) {
  .shape {
    display: none;
    opacity: 0;
  }
}

.navbar-brand {
  padding: 0;
  margin-right: 0;
}

#scrollUp {
  background-color: #cfac6e;
  border-radius: 50%;
  bottom: 20px;
  color: #ffffff;
  font-size: 22px;
  height: 40px;
  line-height: 40px;
  right: 20px;
  text-align: center;
  width: 40px;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(1, 29, 80, 0.1);
          box-shadow: 0px 5px 15px 0px rgba(1, 29, 80, 0.1);
  font-weight: 400;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

#scrollUp:hover {
  background-color: #001659;
}

@media only screen and (max-width: 991px) {
  #scrollUp {
    font-size: 15px;
    height: 30px;
    line-height: 30px;
    right: 30px;
    width: 30px;
  }
}

button {
  border: none;
}

section {
  position: relative;
  z-index: 0;
}

section.pt-235 {
  padding-top: 225px;
}

@-webkit-keyframes sticky {
  0% {
    opacity: 0;
    margin-top: -120px;
  }
  50% {
    opacity: 0;
    margin-top: -60px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes sticky {
  0% {
    opacity: 0;
    margin-top: -120px;
  }
  50% {
    opacity: 0;
    margin-top: -60px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

.equal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.equal > [class*='col-'] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 414px) {
  .d-none-mobile {
    display: none !important;
  }
}

.overlay {
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  width: 40%;
  opacity: 0;
  visibility: hidden;
}

.overlay.active {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: #000;
  z-index: 99;
  opacity: .5;
  visibility: visible;
}

.dark-bg {
  background-color: #000;
}

.dark-bg h1, .dark-bg h2, .dark-bg h3, .dark-bg h4, .dark-bg h5, .dark-bg h6, .dark-bg a {
  color: #FFF;
}

.dark-bg p {
  color: #cacaca;
}

.no-padding .col-12 {
  padding: 0;
}

@media (max-width: 991px) {
  .no-padding .col-12 {
    padding: 0px 15px;
  }
}

img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

a img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@-webkit-keyframes left-to-right {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}

@keyframes left-to-right {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}

@-webkit-keyframes right-to-left {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}

@keyframes right-to-left {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}

@-webkit-keyframes left-to-center {
  0% {
    background-position: left center;
  }
  100% {
    background-position: center center;
  }
}

@keyframes left-to-center {
  0% {
    background-position: left center;
  }
  100% {
    background-position: center center;
  }
}

@-webkit-keyframes right-to-center {
  0% {
    background-position: right center;
  }
  100% {
    background-position: center center;
  }
}

@keyframes right-to-center {
  0% {
    background-position: right center;
  }
  100% {
    background-position: center center;
  }
}

@-webkit-keyframes top-to-bottom {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center bottom;
  }
}

@keyframes top-to-bottom {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center bottom;
  }
}

@-webkit-keyframes top-to-center {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center center;
  }
}

@keyframes top-to-center {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center center;
  }
}

@-webkit-keyframes bottom-to-top {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center top;
  }
}

@keyframes bottom-to-top {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center top;
  }
}

@-webkit-keyframes bottom-to-center {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center center;
  }
}

@keyframes bottom-to-center {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center center;
  }
}

@-webkit-keyframes top-left-to-bottom-right {
  0% {
    background-position: left top;
  }
  100% {
    background-position: right bottom;
  }
}

@keyframes top-left-to-bottom-right {
  0% {
    background-position: left top;
  }
  100% {
    background-position: right bottom;
  }
}

@-webkit-keyframes top-left-to-center {
  0% {
    background-position: left top;
  }
  100% {
    background-position: center center;
  }
}

@keyframes top-left-to-center {
  0% {
    background-position: left top;
  }
  100% {
    background-position: center center;
  }
}

@-webkit-keyframes top-right-to-bottom-left {
  0% {
    background-position: right top;
  }
  100% {
    background-position: left bottom;
  }
}

@keyframes top-right-to-bottom-left {
  0% {
    background-position: right top;
  }
  100% {
    background-position: left bottom;
  }
}

@-webkit-keyframes top-right-to-center {
  0% {
    background-position: right top;
  }
  100% {
    background-position: center center;
  }
}

@keyframes top-right-to-center {
  0% {
    background-position: right top;
  }
  100% {
    background-position: center center;
  }
}

@-webkit-keyframes bottom-left-to-top-right {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: right top;
  }
}

@keyframes bottom-left-to-top-right {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: right top;
  }
}

@-webkit-keyframes bottom-left-to-center {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: center center;
  }
}

@keyframes bottom-left-to-center {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: center center;
  }
}

@-webkit-keyframes bottom-right-to-top-left {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: left top;
  }
}

@keyframes bottom-right-to-top-left {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: left top;
  }
}

@-webkit-keyframes bottom-right-to-center {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: center center;
  }
}

@keyframes bottom-right-to-center {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: center center;
  }
}

@-webkit-keyframes zoomIn {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}

@keyframes zoomIn {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}

.animation_left-to-right {
  background-position: left center;
}

.active .animation_left-to-right {
  -webkit-animation: left-to-right 5s ease 0s 1 normal forwards running;
          animation: left-to-right 5s ease 0s 1 normal forwards running;
}

.animation_right-to-left {
  background-position: right center;
}

.active .animation_right-to-left {
  -webkit-animation: right-to-left 5s ease 0s 1 normal forwards running;
          animation: right-to-left 5s ease 0s 1 normal forwards running;
}

.animation_left-to-center {
  background-position: left center;
}

.active .animation_left-to-center {
  -webkit-animation: left-to-center 5s ease 0s 1 normal forwards running;
          animation: left-to-center 5s ease 0s 1 normal forwards running;
}

.animation_right-to-center {
  background-position: right center;
}

.active .animation_right-to-center {
  -webkit-animation: right-to-center 5s ease 0s 1 normal forwards running;
          animation: right-to-center 5s ease 0s 1 normal forwards running;
}

.animation_top-to-bottom {
  background-position: center top;
}

.active .animation_top-to-bottom {
  -webkit-animation: top-to-bottom 5s ease 0s 1 normal forwards running;
          animation: top-to-bottom 5s ease 0s 1 normal forwards running;
}

.animation_top-to-center {
  background-position: center top;
}

.active .animation_top-to-center {
  -webkit-animation: top-to-center 5s ease 0s 1 normal forwards running;
          animation: top-to-center 5s ease 0s 1 normal forwards running;
}

.animation_bottom-to-top {
  background-position: center bottom;
}

.active .animation_bottom-to-top {
  -webkit-animation: bottom-to-top 5s ease 0s 1 normal forwards running;
          animation: bottom-to-top 5s ease 0s 1 normal forwards running;
}

.animation_bottom-to-center {
  background-position: center bottom;
}

.active .animation_bottom-to-center {
  -webkit-animation: bottom-to-center 5s ease 0s 1 normal forwards running;
          animation: bottom-to-center 5s ease 0s 1 normal forwards running;
}

.animation_top-left-to-bottom-right {
  background-position: left top;
}

.active .animation_top-left-to-bottom-right {
  -webkit-animation: top-left-to-bottom-right 5s ease 0s 1 normal forwards running;
          animation: top-left-to-bottom-right 5s ease 0s 1 normal forwards running;
}

.animation_top-left-to-center {
  background-position: left top;
}

.active .animation_top-left-to-center {
  -webkit-animation: top-left-to-center 5s ease 0s 1 normal forwards running;
          animation: top-left-to-center 5s ease 0s 1 normal forwards running;
}

.animation_top-right-to-bottom-left {
  background-position: right top;
}

.active .animation_top-right-to-bottom-left {
  -webkit-animation: top-right-to-bottom-left 5s ease 0s 1 normal forwards running;
          animation: top-right-to-bottom-left 5s ease 0s 1 normal forwards running;
}

.animation_top-right-to-center {
  background-position: right top;
}

.active .animation_top-right-to-center {
  -webkit-animation: top-right-to-center 5s ease 0s 1 normal forwards running;
          animation: top-right-to-center 5s ease 0s 1 normal forwards running;
}

.animation_bottom-left-to-top-right {
  background-position: left bottom;
}

.active .animation_bottom-left-to-top-right {
  -webkit-animation: bottom-left-to-top-right 5s ease 0s 1 normal forwards running;
          animation: bottom-left-to-top-right 5s ease 0s 1 normal forwards running;
}

.animation_bottom-left-to-center {
  background-position: left bottom;
}

.active .animation_bottom-left-to-center {
  -webkit-animation: bottom-left-to-center 5s ease 0s 1 normal forwards running;
          animation: bottom-left-to-center 5s ease 0s 1 normal forwards running;
}

.animation_bottom-right-to-top-left {
  background-position: right bottom;
}

.active .animation_bottom-right-to-top-left {
  -webkit-animation: bottom-right-to-top-left 5s ease 0s 1 normal forwards running;
          animation: bottom-right-to-top-left 5s ease 0s 1 normal forwards running;
}

.animation_bottom-right-to-center {
  background-position: right bottom;
}

.active .animation_bottom-right-to-center {
  -webkit-animation: bottom-right-to-center 5s ease 0s 1 normal forwards running;
          animation: bottom-right-to-center 5s ease 0s 1 normal forwards running;
}

/* Alignments
--------------------------------------------- */
.alignleft {
  /*rtl:ignore*/
  float: left;
  /*rtl:ignore*/
  margin-right: 1.5em;
  margin-bottom: 1.5em;
}

.alignright {
  /*rtl:ignore*/
  float: right;
  /*rtl:ignore*/
  margin-left: 1.5em;
  margin-bottom: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5em;
}

.scroll-up-btn a {
  display: inline-block;
  padding: 16px 20px;
  border-radius: 10px;
  line-height: 1;
  background-color: #ffcb61;
}

.scroll-up-btn a:hover {
  color: #fff !important;
  background-color: #001659;
}

/* ---------------------------------
    Offset Canvas Menu Style 
------------------------------------*/
.offset-menu {
  position: fixed;
  height: 100vh;
  top: 0;
  z-index: 9999999;
  opacity: 0;
  visibility: hidden;
  right: -30%;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  background-color: #001659;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
}

.offset-menu.show {
  opacity: 1;
  visibility: visible;
  right: 0;
}

.offset-menu .offset-menu-wrapper {
  width: 360px;
  padding: 80px 30px;
}

.offset-menu .theme-btn {
  border-radius: 50px;
  padding: 20px 45px;
  font-weight: 500;
  margin-top: 40px;
}

.offset-menu .offset-menu-section {
  margin-top: 40px;
}

.offset-menu .offset-menu-section h3, .offset-menu .offset-menu-section h4, .offset-menu .offset-menu-section h5 {
  line-height: 1;
  margin-bottom: 20px;
}

.offset-menu .offset-menu-section a {
  margin-bottom: 10px;
}

.offset-menu .offset-menu-section ul li {
  margin-bottom: 5px;
}

.offset-menu .offset-menu-section ul li span {
  margin-right: 10px;
}

.offset-menu #offset-menu-close-btn {
  position: fixed;
  right: 30px;
  font-size: 28px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  color: #fff;
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  z-index: 9;
  top: 20px;
}

.offset-menu #offset-menu-close-btn:hover {
  color: #ff5e14;
}

.offset-menu .offset-menu-footer {
  margin-top: 35px;
}

.offset-menu .offset-menu-social-icon a {
  font-size: 16px;
  background: #001659;
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 5px;
}

.offset-menu .offset-menu-social-icon a:hover {
  background: #cfac6e;
}

.offset-menu img {
  display: inline-block;
  background: #fff;
  padding: 10px 20px;
}

.map-iframe {
  display: block;
}

.map-iframe iframe {
  width: 100%;
  height: 650px;
}

@media (max-width: 991px) {
  .map-iframe iframe {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .map-iframe iframe {
    height: 300px;
  }
}

@-webkit-keyframes playVideo {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  80% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes playVideo {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  80% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@-webkit-keyframes dimond {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes dimond {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes bounceUp {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes bounceUp {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes moverightbounce {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes moverightbounce {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0px;
  }
}

.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes guraguri {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes guraguri {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
            transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
            transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
            transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

@-webkit-keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
            transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
            transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
            transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

/* ----------------------------------
  All Aniamtion Styles
 ------------------------------------ */
/* ------------------------------------------------------------------
   Template Default Button, Input Styles - Value Change with Your's Need
 -------------------------------------------------------------------- */
.btnd, .theme-btn, .video-btn {
  line-height: 1;
  display: inline-block;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-transform: capitalize;
}

.theme-btn {
  color: #000;
  padding: 24px 36px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  background-color: #febc35;
  border: 2px solid #febc35;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media (max-width: 991px) {
  .theme-btn {
    padding: 16px 30px;
    font-weight: 400;
    border-radius: 5px;
  }
}

@media (max-width: 767px) {
  .theme-btn {
    padding: 14px 28px;
    font-size: 15px;
  }
}

.theme-btn.theme-2 {
  background-color: #ff5e14;
  border: 2px solid #ff5e14;
  color: #fff;
  border-radius: 50px;
  padding: 22px 40px;
}

.theme-btn.theme-2:hover {
  background-color: #001659;
  border: 2px solid #001659;
}

.theme-btn.theme-2.white {
  background: #fff;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
  color: #001659;
  border-color: #fff;
}

.theme-btn.theme-2.white:hover {
  background-color: #001659;
  color: #fff;
  border-color: #001659;
}

.theme-btn.theme-3 {
  -webkit-clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
  clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
  border-radius: 0px;
  padding: 22px 50px;
}

@media (max-width: 580px) {
  .theme-btn {
    padding: 16px 35px;
  }
}

@media (max-width: 480px) {
  .theme-btn {
    padding: 15px 30px;
    font-size: 15px;
  }
}

.theme-btn i {
  margin-left: 15px;
}

.theme-btn.no-fil {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
  margin-left: 15px;
}

@media (max-width: 500px) {
  .theme-btn.no-fil {
    margin-left: 0;
  }
}

.theme-btn.no-fil:hover {
  background-color: #cfac6e;
  border: 2px solid #cfac6e;
}

.theme-btn.black {
  background-color: #000;
  border-color: #000;
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
  color: #FFF;
}

.theme-btn.black:hover {
  background-color: #cfac6e;
  border: 2px solid #cfac6e;
}

.theme-btn.minimal-btn {
  color: #fff;
  border: 2px solid #ebebeb;
  background: transparent;
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
}

.theme-btn.minimal-btn:hover {
  background-color: #cfac6e;
  border: 2px solid #cfac6e;
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
  color: #FFF;
}

.theme-btn:hover {
  background-color: #000;
  border: 2px solid #000;
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
  color: #FFF;
}

.video-btn {
  font-size: 16px;
  font-weight: 700;
  color: #726ceb;
}

.video-btn i {
  margin-right: 10px;
}

.video-play-btn .play-video {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-color: #f86048;
  color: #FFF;
  line-height: 120px;
  -webkit-transition: .3s;
  transition: .3s;
  text-align: center;
  -webkit-animation: playVideo infinite 3.5s;
          animation: playVideo infinite 3.5s;
  display: inline-block;
  margin: 0px auto;
}

@media (max-width: 767px) {
  .video-play-btn .play-video {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}

.video-play-btn .play-video:hover {
  color: #cfac6e;
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.video-play-btn .play-text {
  font-weight: 700;
}

.cat-btn {
  font-size: 16px;
  line-height: 1;
  padding: 20px 30px;
  font-weight: 700;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  background: transparent;
  display: inline-block;
  margin-right: 10px;
  margin-top: 30px;
}

.cat-btn i {
  margin-right: 10px;
}

.cat-btn.color {
  background-color: #00baa3;
  border-color: #00baa3;
  color: #fff;
}

.cat-btn:hover {
  background-color: #00baa3;
  border-color: #00baa3;
  border-style: solid;
  border-width: 2px;
}

.preloader {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999999;
}

.preloader .animation-preloader {
  z-index: 1000;
}

.preloader .animation-preloader .spinner {
  -webkit-animation: spinner 1s infinite linear;
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: #001659;
  height: 9em;
  margin: 0 auto 3.5em auto;
  width: 9em;
}

.preloader .animation-preloader .txt-loading {
  font: bold 5em "Poppins", sans-serif, sans-serif;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 500px) {
  .preloader .animation-preloader .txt-loading {
    font-size: 3em;
  }
}

.preloader .animation-preloader .txt-loading .letters-loading {
  color: #cfac6e;
  position: relative;
}

.preloader .animation-preloader .txt-loading .letters-loading:before {
  -webkit-animation: letters-loading 4s infinite;
  animation: letters-loading 4s infinite;
  color: #cfac6e;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  font-family: "Poppins", sans-serif;
  position: absolute;
  top: -3px;
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.preloader.dark .animation-preloader .spinner {
  border-color: #001659;
  border-top-color: #fff;
}

.preloader.dark .animation-preloader .txt-loading .letters-loading {
  color: #cfac6e;
}

.preloader.dark .animation-preloader .txt-loading .letters-loading:before {
  color: #cfac6e;
}

.preloader p {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 8px;
  color: #001659;
}

.preloader .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 1;
  pointer-events: none;
}

.preloader .loader .row {
  height: 100%;
}

.preloader .loader .loader-section {
  padding: 0px;
}

.preloader .loader .loader-section .bg {
  background-color: #FFF;
  height: 100%;
  left: 0;
  width: 100%;
  -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

.preloader .loader.dark_bg .loader-section .bg {
  background: #efc94c;
}

.preloader.loaded .animation-preloader {
  opacity: 0;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.preloader.loaded .loader-section .bg {
  width: 0;
  -webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

/* ----------------------------------
    Template Section Styles
 ------------------------------------*/
header {
  width: 100%;
  z-index: 9999;
}

header.header-1 .top-bar {
  background-color: #000;
  padding: 15px 0px;
}

@media (max-width: 1199px) {
  header.header-1 .top-bar {
    padding: 12px 0px;
  }
}

header.header-1 .top-bar a {
  color: #FFF;
  font-weight: 600;
  line-height: 1;
  position: relative;
  padding-left: 46px;
  margin-right: 40px;
}

@media (max-width: 1199px) {
  header.header-1 .top-bar a {
    font-size: 14px;
  }
}

header.header-1 .top-bar a:hover {
  color: #cfac6e;
}

header.header-1 .top-bar a::before {
  width: 40px;
  height: 2px;
  background-color: #FFF;
  opacity: 0.2;
  position: absolute;
  left: 0;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

header.header-1 .top-bar a span {
  font-weight: 400;
}

header.header-1 .logo {
  padding: 32px 0px;
}

@media (max-width: 991px) {
  header.header-1 .logo {
    padding: 20px 0px;
  }
}

header.header-1 .menu-wrapper {
  position: relative;
}

header.header-1 .menu-wrapper::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  content: "";
  position: absolute;
  background-color: #fff;
}

header.header-1 .main-menu-area {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  z-index: 99;
  margin-bottom: -36px;
  -webkit-box-shadow: 0px -16px 32px 0px rgba(0, 0, 0, 0.04);
          box-shadow: 0px -16px 32px 0px rgba(0, 0, 0, 0.04);
}

header.header-1 .main-menu ul {
  line-height: 1;
}

header.header-1 .main-menu ul > li {
  display: inline-block;
  line-height: 1;
}

header.header-1 .main-menu ul > li > a {
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  color: #000;
  line-height: 1;
  padding: 27px 32.5px;
  overflow: hidden;
}

header.header-1 .main-menu ul > li:hover > a {
  color: #febc35;
}

header.header-1 .main-menu ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

header.header-1 .main-menu ul > li > ul {
  position: absolute;
  top: 100%;
  width: 230px;
  background-color: #febc35;
  left: 0;
  z-index: 999;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  padding: 10px 0px;
}

header.header-1 .main-menu ul > li > ul li {
  display: block;
}

header.header-1 .main-menu ul > li > ul li a {
  display: block;
  color: #000;
  padding: 12px 25px;
}

header.header-1 .main-menu ul > li > ul li a:hover {
  color: #fff;
}

header.header-1 .main-menu ul > li > ul ul {
  left: 100%;
  top: 0;
}

header.header-1 .search-btn, header.header-1 .side-menu-toggle {
  background-color: #febc35;
  color: #000;
  padding: 25px;
  line-height: 1;
  display: inline;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

@media (max-width: 1199px) {
  header.header-1 .search-btn, header.header-1 .side-menu-toggle {
    background: transparent;
    padding: 20px;
  }
  header.header-1 .search-btn:hover, header.header-1 .side-menu-toggle:hover {
    background: transparent;
    color: #febc35;
  }
}

header.header-1 .search-btn:hover, header.header-1 .side-menu-toggle:hover {
  background-color: #000;
  color: #fff;
}

header.header-1 .side-menu-toggle {
  background-color: #febc35;
  border-radius: 0px 10px 10px 0px;
}

@media (max-width: 1199px) {
  header.header-1 .side-menu-toggle {
    background: transparent;
  }
  header.header-1 .side-menu-toggle:hover {
    background: transparent;
    color: #febc35;
  }
}

header .header-contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  float: right;
}

@media (max-width: 991px) {
  header .header-contact-info {
    float: inherit;
    padding-bottom: 20px;
    margin-top: 10px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

header .header-contact-info .single-element {
  display: inline-block;
  text-align: left;
  position: relative;
  padding-left: 36px;
  margin-left: 50px;
}

@media (max-width: 1199px) {
  header .header-contact-info .single-element {
    margin-left: 20px;
    padding-left: 26px;
  }
}

@media (max-width: 991px) {
  header .header-contact-info .single-element {
    margin-left: 0;
    margin-right: 15px;
  }
}

header .header-contact-info .single-element::before {
  width: 20px;
  height: 2px;
  background-color: #cfac6e;
  position: absolute;
  left: 0;
  content: "";
  top: 13px;
}

@media (max-width: 1199px) {
  header .header-contact-info .single-element::before {
    width: 15px;
  }
}

header .header-contact-info .single-element a {
  display: block;
  font-size: 20px;
  color: #000;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

@media (max-width: 1199px) {
  header .header-contact-info .single-element a {
    font-size: 16px;
  }
}

header .header-btn {
  margin-left: 60px;
}

header .header-btn a {
  padding: 23px 37px;
  line-height: 1;
}

@media (max-width: 991px) {
  header .header-btn a {
    padding: 16px 28px;
    font-size: 15px;
    font-weight: 500;
  }
}

header .header-btn a:hover {
  background-color: #febc35;
  border-color: #febc35;
}

header .logo a {
  display: block;
}

header.header-2 {
  position: relative;
}

header.header-2 .container-fluid {
  padding: 0;
  padding-right: 40px;
}

header.header-2 .logo {
  display: inline-block;
  position: relative;
  padding: 30px;
  padding-left: 40px;
}

@media (max-width: 991px) {
  header.header-2 .logo {
    padding: 20px !important;
  }
}

@media (max-width: 767px) {
  header.header-2 .logo {
    padding: 15px !important;
  }
}

header.header-2 .logo::before {
  position: absolute;
  width: 300%;
  height: 110%;
  right: 0;
  top: 0;
  background-color: #ff5e14;
  content: "";
}

@media (max-width: 1320px) {
  header.header-2 .logo::before {
    height: 100%;
  }
}

@media (max-width: 1320px) {
  header.header-2 .menu-wrapper {
    display: none;
  }
}

header.header-2 .main-menu {
  display: inline-block;
}

header.header-2 .main-menu ul {
  line-height: 1;
}

header.header-2 .main-menu ul > li {
  display: inline-block;
  line-height: 1;
}

header.header-2 .main-menu ul > li > a {
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  color: #000;
  line-height: 1;
  padding: 47px 25px;
  overflow: hidden;
}

@media (max-width: 1500px) {
  header.header-2 .main-menu ul > li > a {
    padding: 47px 20px;
  }
}

header.header-2 .main-menu ul > li:hover > a {
  color: #febc35;
}

header.header-2 .main-menu ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

header.header-2 .main-menu ul > li > ul {
  position: absolute;
  top: 100%;
  width: 230px;
  background-color: #febc35;
  left: 0;
  z-index: 999;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  padding: 10px 0px;
}

header.header-2 .main-menu ul > li > ul li {
  display: block;
}

header.header-2 .main-menu ul > li > ul li a {
  display: block;
  color: #000;
  padding: 12px 25px;
}

header.header-2 .main-menu ul > li > ul li a:hover {
  color: #fff;
}

header.header-2 .main-menu ul > li > ul ul {
  left: 100%;
  top: 0;
}

header.header-2 .header-right {
  float: right;
  margin-top: 25px;
}

@media (max-width: 991px) {
  header.header-2 .header-right {
    margin-top: 0;
    padding-top: 15px;
  }
}

@media (max-width: 750px) {
  header.header-2 .header-right {
    display: none;
  }
}

header.header-2 .header-right .theme-btn {
  margin-right: 30px;
}

@media (max-width: 1500px) {
  header.header-2 .header-right .theme-btn {
    margin-right: 0px;
  }
}

header.header-2 .header-right .circle-btn {
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 52px;
  display: inline-block;
  color: #001659;
  margin: 0 7px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

header.header-2 .header-right .circle-btn:hover {
  background-color: #ff5e14;
  color: #fff;
}

header.header-2 .mobile-menu-wrap {
  display: none;
  position: relative;
  z-index: 99999;
  height: 100%;
}

@media (max-width: 1320px) {
  header.header-2 .mobile-menu-wrap {
    display: block !important;
    padding-top: 40px;
  }
}

@media (max-width: 991px) {
  header.header-2 .mobile-menu-wrap {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  header.header-2 .mobile-menu-wrap {
    padding-top: 26px;
  }
}

header.header-3 {
  background-color: #fff;
  position: relative;
  z-index: 1;
}

@media (max-width: 1319px) {
  header.header-3 {
    padding: 10px 0px;
  }
}

@media (max-width: 575px) {
  header.header-3 {
    padding: 20px 0px;
  }
}

header.header-3::before {
  position: absolute;
  content: "";
  height: 100%;
  background: #cfac6e;
  width: 620px;
  -webkit-clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);
  top: 0;
  right: 0;
}

@media (max-width: 1319px) {
  header.header-3::before {
    width: 420px;
  }
}

@media (max-width: 991px) {
  header.header-3::before {
    display: none;
  }
}

@media (min-width: 1600px) {
  header.header-3 .container {
    max-width: 1445px;
  }
}

@media (max-width: 1319px) {
  header.header-3 .header-none {
    display: none;
  }
}

header.header-3 .main-menu ul {
  line-height: 1;
}

header.header-3 .main-menu ul > li {
  display: inline-block;
  line-height: 1;
}

header.header-3 .main-menu ul > li > a {
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  color: #777;
  line-height: 1;
  padding: 32px;
  overflow: hidden;
}

header.header-3 .main-menu ul > li:hover > a {
  color: #febc35;
}

header.header-3 .main-menu ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

header.header-3 .main-menu ul > li > ul {
  position: absolute;
  top: 100%;
  width: 230px;
  background-color: #001659;
  left: 0;
  z-index: 9999;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  padding: 10px 0px;
}

header.header-3 .main-menu ul > li > ul li {
  display: block;
}

header.header-3 .main-menu ul > li > ul li a {
  display: block;
  color: #fff;
  padding: 12px 25px;
}

header.header-3 .main-menu ul > li > ul li a:hover {
  color: #cfac6e;
}

header.header-3 .main-menu ul > li > ul ul {
  left: 100%;
  top: 0;
}

header.header-3 .theme-btn {
  background-color: #b28e5f;
  border-color: #b28e5f;
  color: #fff;
}

@media (max-width: 1199px) {
  header.header-3 .theme-btn {
    padding: 20px 42px;
  }
}

@media (max-width: 767px) {
  header.header-3 .theme-btn {
    padding: 18px 36px;
  }
}

@media (max-width: 575px) {
  header.header-3 .theme-btn {
    display: none;
  }
}

@media (max-width: 575px) {
  header.header-3 .theme-btn.d-btn {
    display: block;
  }
}

header.header-3 .theme-btn:hover {
  background-color: #001659;
  border-color: #001659;
  color: #fff;
}

header.header-3 .mobile-menu-bar {
  display: none;
}

@media (max-width: 1319px) {
  header.header-3 .mobile-menu-bar {
    display: block;
  }
}

@media (max-width: 1319px) {
  header.header-3 .d-in-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.lan-select {
  font-weight: 600;
  float: right;
}

@media (max-width: 1200px) {
  .lan-select {
    font-weight: 400;
    font-size: 14px;
  }
}

.lan-select select {
  border: 0px;
  background: transparent;
  color: #FFF;
  cursor: pointer;
}

.lan-select select option {
  color: #000;
  border: 0px;
}

.top-bar {
  padding: 0px 25px;
  background: #001659;
  position: relative;
  overflow: hidden;
}

.top-bar.style-3 {
  padding: 13px 0px;
  display: block;
}

@media (min-width: 1600px) {
  .top-bar.style-3 .container {
    max-width: 1445px;
  }
}

.top-bar .contact-data ul li {
  font-size: 14px;
  line-height: 1;
  color: #8f9aba;
  display: inline-block;
  margin-right: 35px;
}

.top-bar .contact-data ul li i {
  color: #fff;
  margin-right: 5px;
}

@media (max-width: 1199px) {
  .top-bar .contact-data ul li {
    margin-right: 15px;
  }
}

.top-bar .social_link a {
  color: #8d98ba;
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  line-height: 1;
}

.top-bar .social_link a:hover {
  color: #fff;
}

@media (max-width: 1199px) {
  .top-bar .social_link a {
    margin-right: 8px;
  }
}

.top-bar .lan-select-menu {
  padding: 16px 30px;
  display: inline-block;
  position: relative;
}

.top-bar .lan-select-menu::before {
  position: absolute;
  width: 200%;
  height: 100%;
  content: "";
  background: #000b2c;
  top: 0;
  left: 0;
}

.top-bar .lan-select-menu form {
  font-size: 14px;
}

.top-bar .lan-select-menu form select {
  color: #fff;
  border: 0px;
  background: transparent;
}

.top-bar .lan-select-menu form select option {
  color: #FFF;
  background: #000b2c;
  border: 0px;
}

.page-banner-wrap .breadcrumb-wrap {
  overflow: hidden;
}

.page-banner-wrap .breadcrumb {
  background: transparent;
  padding: 0;
  margin-bottom: 10px;
}

.page-banner-wrap .breadcrumb li {
  color: #cfac6e;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}

.page-banner-wrap .breadcrumb li a {
  color: #fff;
}

.page-banner-wrap .breadcrumb li a:hover {
  color: #ff5e14;
}

.page-banner-wrap .breadcrumb li + li::before {
  color: #c5c2c2;
  content: "|";
}

.mobile-nav {
  position: fixed;
  right: -380px;
  top: 0;
  width: 320px;
  height: 100vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
  -webkit-transition: right .5s ease-in;
  transition: right .5s ease-in;
  background-color: #000;
  padding: 20px;
  z-index: 999999999;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  /* Vertical Menu Style */
}

.mobile-nav.show {
  right: 0px;
}

.mobile-nav .close-nav {
  border: 0;
  background: transparent;
  color: #fff;
  border-radius: 30px;
  font-size: 20px;
  position: absolute;
  left: 20px;
  top: 10px;
}

.mobile-nav .sidebar-nav {
  margin-top: 30px;
}

.mobile-nav .sidebar-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
}

.mobile-nav .sidebar-nav .metismenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mobile-nav .sidebar-nav .metismenu > li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mobile-nav .sidebar-nav .metismenu a {
  position: relative;
  display: block;
  padding: 10px 15px;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  text-decoration: none;
  color: #fff;
  outline-width: 0;
  text-transform: capitalize;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.mobile-nav .sidebar-nav .metismenu ul a {
  padding: 10px 15px 10px 35px;
  position: relative;
}

.mobile-nav .sidebar-nav .metismenu ul a:hover {
  padding-left: 40px;
}

.mobile-nav .sidebar-nav .metismenu ul a:hover::before {
  background-color: #fff;
}

.mobile-nav .sidebar-nav .metismenu ul a::before {
  position: absolute;
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  content: "";
  top: 50%;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.mobile-nav .sidebar-nav .metismenu ul ul {
  list-style-type: circle !important;
}

.mobile-nav .sidebar-nav .metismenu ul ul a {
  padding: 10px 15px 10px 45px;
}

.mobile-nav .sidebar-nav .metismenu a:hover,
.mobile-nav .sidebar-nav .metismenu a:focus,
.mobile-nav .sidebar-nav .metismenu a:active {
  text-decoration: none;
  color: #f8f9fa;
  background: #cfac6e;
}

#hamburger {
  color: #000;
  font-size: 32px;
  line-height: 1;
  text-align: right;
  font-weight: 700;
}

#hamburger i {
  cursor: pointer;
}

.action-bar {
  margin-top: 30px;
  padding-left: 20px;
}

.action-bar a {
  color: #fff;
  display: block;
  margin-top: 12px;
}

.action-bar a i {
  margin-right: 10px;
}

.action-bar a.theme-btn {
  text-align: center;
}

/* ---------------------------------
    Offset Canvas Menu Style 
------------------------------------*/
.offset-menu {
  position: fixed;
  height: 100vh;
  top: 0;
  z-index: 9999999;
  opacity: 0;
  visibility: hidden;
  right: -30%;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  background-color: #001659;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
}

.offset-menu.show {
  opacity: 1;
  visibility: visible;
  right: 0;
}

.offset-menu .offset-menu-wrapper {
  width: 360px;
  padding: 80px 30px;
}

.offset-menu .theme-btn {
  border-radius: 50px;
  padding: 20px 45px;
  font-weight: 500;
  margin-top: 40px;
}

.offset-menu .offset-menu-section {
  margin-top: 40px;
}

.offset-menu .offset-menu-section h3, .offset-menu .offset-menu-section h4, .offset-menu .offset-menu-section h5 {
  line-height: 1;
  margin-bottom: 20px;
}

.offset-menu .offset-menu-section a {
  margin-bottom: 10px;
}

.offset-menu .offset-menu-section ul li {
  margin-bottom: 5px;
}

.offset-menu .offset-menu-section ul li span {
  margin-right: 10px;
}

.offset-menu #offset-menu-close-btn {
  position: fixed;
  right: 30px;
  font-size: 28px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  color: #fff;
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  z-index: 9;
  top: 20px;
}

.offset-menu #offset-menu-close-btn:hover {
  color: #ff5e14;
}

.offset-menu .offset-menu-footer {
  margin-top: 35px;
}

.offset-menu .offset-menu-social-icon a {
  font-size: 16px;
  background: #001659;
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 5px;
}

.offset-menu .offset-menu-social-icon a:hover {
  background: #cfac6e;
}

.offset-menu img {
  display: inline-block;
  background: #fff;
  padding: 10px 20px;
}

/* ----------------------------------
   Hero Section - Styles
 ------------------------------------ */
.hero-1 .single-slide {
  padding: 200px 0px 180px;
}

@media (max-width: 1199px) {
  .hero-1 .single-slide {
    padding: 160px 0px 120px;
  }
}

@media (max-width: 991px) {
  .hero-1 .single-slide {
    padding: 120px 0px 100px;
  }
}

@media (max-width: 767px) {
  .hero-1 .single-slide {
    padding: 100px 0px 80px;
  }
}

.hero-1 .single-slide .hero-contents p {
  color: #fff;
  margin-bottom: 5px;
}

.hero-1 .single-slide .hero-contents h1 {
  color: #fff;
  font-size: 140px;
  line-height: 90%;
  letter-spacing: -3px;
}

@media (max-width: 1199px) {
  .hero-1 .single-slide .hero-contents h1 {
    font-size: 110px;
  }
}

@media (max-width: 991px) {
  .hero-1 .single-slide .hero-contents h1 {
    font-size: 80px;
    line-height: 1;
  }
}

@media (max-width: 767px) {
  .hero-1 .single-slide .hero-contents h1 {
    font-size: 70px;
  }
}

.hero-1 .single-slide .hero-contents a {
  margin-top: 40px;
  margin-right: 20px;
}

.hero-2 {
  overflow: hidden;
}

.hero-2 .single-slide {
  padding: 185px 0px 450px;
  background-color: #f6f6f6;
  position: relative;
}

@media (max-width: 1400px) {
  .hero-2 .single-slide {
    padding: 160px 0px 300px;
  }
}

@media (max-width: 1280px) {
  .hero-2 .single-slide {
    padding: 160px 0px 250px;
  }
}

@media (max-width: 1200px) {
  .hero-2 .single-slide {
    padding: 120px 0px 200px;
  }
}

@media (max-width: 991px) {
  .hero-2 .single-slide {
    padding: 100px 0;
  }
}

.hero-2 .single-slide .hero-contents h1 {
  font-size: 120px;
  line-height: 1;
  display: inline-block;
  color: #001659;
  margin-right: 50px;
}

@media (max-width: 1280px) {
  .hero-2 .single-slide .hero-contents h1 {
    font-size: 100px;
  }
}

@media (max-width: 1200px) {
  .hero-2 .single-slide .hero-contents h1 {
    font-size: 90px;
    display: block;
  }
}

@media (max-width: 767px) {
  .hero-2 .single-slide .hero-contents h1 {
    font-size: 80px;
    margin-right: 0;
  }
}

@media (max-width: 500px) {
  .hero-2 .single-slide .hero-contents h1 {
    font-size: 70px;
    margin-right: 0;
  }
}

@media (max-width: 400px) {
  .hero-2 .single-slide .hero-contents h1 {
    font-size: 60px;
    margin-right: 0;
  }
}

.hero-2 .single-slide .hero-contents h1:last-child {
  display: block;
  margin-left: 50px;
}

@media (max-width: 1200px) {
  .hero-2 .single-slide .hero-contents h1:last-child {
    margin-left: 0;
  }
}

.hero-2 .single-slide .hero-contents a {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media (max-width: 1200px) {
  .hero-2 .single-slide .hero-contents a {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    margin: 15px 0px;
  }
}

.hero-3 {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.hero-3 .single-slide {
  padding: 150px 0px;
  position: relative;
  z-index: 2;
  background-color: #cfac6e;
}

@media (max-width: 1199px) {
  .hero-3 .single-slide {
    padding: 120px 0px;
  }
}

@media (max-width: 767px) {
  .hero-3 .single-slide {
    padding: 100px 0px;
  }
}

.hero-3 .single-slide::before {
  position: absolute;
  width: 1100px;
  height: 667px;
  bottom: 0;
  left: 0;
  background-image: url("../img/home3/hero-pattern.png");
  background-repeat: x-repeat;
  background-position: left bottom;
  content: "";
  z-index: -1;
}

.hero-3 .single-slide::after {
  width: 68.5%;
  height: 400%;
  position: absolute;
  content: "";
  right: 0;
  top: -30%;
  background-color: #001248;
  z-index: -1;
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}

@media (max-width: 1199px) {
  .hero-3 .single-slide::after {
    display: none;
  }
}

.hero-3 .single-slide .hero-contents p {
  color: #001659;
}

.hero-3 .single-slide .hero-contents h1 {
  font-size: 90px;
  line-height: 1.22;
  display: inline-block;
  color: #001659;
  font-weight: 700;
}

@media (max-width: 1199px) {
  .hero-3 .single-slide .hero-contents h1 {
    font-size: 80px;
  }
}

@media (max-width: 575px) {
  .hero-3 .single-slide .hero-contents h1 {
    font-size: 60px;
  }
}

@media (max-width: 485px) {
  .hero-3 .single-slide .hero-contents h1 {
    font-size: 50px;
  }
}

.hero-3 .single-slide .hero-contents a {
  margin-top: 30px;
}

@media (max-width: 575px) {
  .hero-3 .single-slide .hero-contents a {
    margin-top: 20px;
  }
}

.hero-3 .single-slide .hero-contents a.theme-3 {
  border-color: #001659;
  background-color: #001659;
  color: #fff;
}

.hero-3 .single-slide .hero-contents a.theme-3:last-child {
  border-color: #fff;
  background-color: #fff;
  color: #001659;
}

.hero-3 .single-slide .hero-img {
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  height: 500px;
  width: 130%;
  margin-left: -100px;
  position: relative;
  z-index: 1;
}

.section-title {
  position: relative;
  margin-top: -5px;
}

.section-title.style-2 p {
  color: #ff5e14;
}

.section-title.style-2 h1 {
  color: #001659;
}

.section-title span {
  position: absolute;
  content: "";
  font-weight: 700;
  font-size: 160px;
  letter-spacing: -3px;
  color: #fff;
  text-transform: capitalize;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1;
  bottom: -5px;
  line-height: 1;
}

@media (max-width: 1199px) {
  .section-title span {
    font-size: 120px;
  }
}

@media (max-width: 767px) {
  .section-title span {
    font-size: 80px;
  }
}

.section-title p {
  margin-bottom: 5px;
  text-transform: capitalize;
}

.section-title h1 {
  text-transform: capitalize;
}

.section-title-2 {
  position: relative;
  z-index: 2;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .section-title-2 {
    margin-bottom: 20px;
  }
}

.section-title-2 span {
  font-size: 180px;
  line-height: 1;
  color: rgba(0, 22, 89, 0);
  font-weight: 600;
  -webkit-text-stroke: 1px #efefef;
  position: absolute;
  top: -38%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: .9;
  z-index: -1;
}

@media (max-width: 1200px) {
  .section-title-2 span {
    font-size: 120px;
  }
}

@media (max-width: 991px) {
  .section-title-2 span {
    font-size: 80px;
  }
}

@media (max-width: 767px) {
  .section-title-2 span {
    font-size: 70px;
  }
}

.section-title-2 p {
  color: #ff5e14;
}

.section-title-2 h1 {
  color: #001659;
}

.section-title-2 .icon {
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.08);
  width: 120px;
  height: 120px;
  line-height: 120px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  position: relative;
  margin-bottom: 50px;
}

.section-title-2 .icon::before {
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  border-color: #eaeaea;
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  z-index: -1;
}

.section-title-3 p {
  background-color: #cfac6e;
  padding: 12px 30px;
  line-height: 1;
  color: #001248;
  text-transform: capitalize;
  display: inline-block;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  margin-bottom: 15px;
  font-weight: 500;
}

.section-title-3 h1 {
  color: #001248;
}

.section-padding {
  padding: 120px 0px;
}

@media (max-width: 991px) {
  .section-padding {
    padding: 100px 0px;
  }
}

@media (max-width: 767px) {
  .section-padding {
    padding: 80px 0px;
  }
}

.section-padding-two {
  padding: 140px 0px;
}

@media (max-width: 1400px) {
  .section-padding-two {
    padding: 120px 0px;
  }
}

@media (max-width: 991px) {
  .section-padding-two {
    padding: 100px 0px;
  }
}

@media (max-width: 767px) {
  .section-padding-two {
    padding: 80px 0px;
  }
}

.section-bg {
  background-color: #f6f6f6;
}

.theme-bg {
  background-color: #cfac6e;
}

.page-banner-wrap {
  background-color: #cfac6e;
  padding-bottom: 90px;
  padding-top: 120px;
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .page-banner-wrap {
    padding-bottom: 80px;
    padding-top: 100px;
  }
}

@media (max-width: 767px) {
  .page-banner-wrap {
    padding-bottom: 70px;
    padding-top: 70px;
  }
}

.page-banner-wrap::before {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: .10;
  z-index: -1;
}

.page-banner-wrap .page-heading h1 {
  font-size: 80px;
  line-height: 110%;
}

@media (max-width: 991px) {
  .page-banner-wrap .page-heading h1 {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .page-banner-wrap .page-heading h1 {
    font-size: 40px;
  }
}

/* ----------------------------------
	About Us Section Styles
 ------------------------------------ */
.about-us-section .about-shots {
  position: relative;
}

.about-us-section .about-shots .about-top-img {
  width: 320px;
  height: 280px;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translateY(-115px);
          transform: translateY(-115px);
  border-style: solid;
  border-width: 10px;
  border-color: white;
  background-color: #fff;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(32, 36, 38, 0.1);
          box-shadow: 0px 16px 32px 0px rgba(32, 36, 38, 0.1);
}

@media (min-width: 991px) and (max-width: 1199px) {
  .about-us-section .about-shots .about-top-img {
    right: -30px;
  }
}

@media (max-width: 767px) {
  .about-us-section .about-shots .about-top-img {
    -webkit-transform: translateX(50px) translateY(-115px);
            transform: translateX(50px) translateY(-115px);
  }
}

@media (max-width: 500px) {
  .about-us-section .about-shots .about-top-img {
    width: 240px;
    height: 200px;
    -webkit-transform: translateY(-145px) translateX(-20px);
            transform: translateY(-145px) translateX(-20px);
    background-color: #fff;
  }
}

.about-us-section .about-shots .our-experience {
  width: 261px;
  height: 216px;
  position: absolute;
  bottom: 100px;
  right: -100px;
  background-color: #00baa3;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.6;
}

@media (min-width: 991px) and (max-width: 1199px) {
  .about-us-section .about-shots .our-experience {
    bottom: -30px;
  }
}

@media (max-width: 991px) {
  .about-us-section .about-shots .our-experience {
    right: 20px;
  }
}

@media (max-width: 767px) {
  .about-us-section .about-shots .our-experience {
    right: -50px;
    bottom: -30px;
  }
}

.about-us-section .about-shots .our-experience h1 {
  margin-top: 50px;
  font-size: 80px;
  line-height: 1;
}

.about-us-section .list-box {
  margin-top: 30px;
}

.about-us-section .list-box li {
  width: 50%;
  float: left;
  overflow: hidden;
  position: relative;
  padding-left: 15px;
  font-weight: 700;
  margin-top: 10px;
  display: block;
}

@media (max-width: 585px) {
  .about-us-section .list-box li {
    width: 100%;
    float: none;
  }
}

.about-us-section .list-box li::before {
  position: absolute;
  left: 0;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 7px;
  height: 2px;
  background: #00baa3;
}

.about-us-section .list-box li:nth-child(2)::before {
  background-color: #d55342;
}

.about-us-section .list-box li:nth-child(3n)::before {
  background-color: #efc94c;
}

.about-us-section .list-box li:nth-child(4n)::before {
  background-color: #9238a4;
}

.about-us-section .testimonial-quote {
  margin-top: 35px;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(129, 129, 129, 0.06);
          box-shadow: 0px 16px 32px 0px rgba(129, 129, 129, 0.06);
  background-color: #fff;
  padding: 40px;
  position: relative;
  z-index: 1;
}

@media (max-width: 585px) {
  .about-us-section .testimonial-quote {
    overflow: hidden;
    padding: 30px;
  }
}

.about-us-section .testimonial-quote::before {
  position: absolute;
  right: -25px;
  content: "";
  bottom: -20px;
  width: 141px;
  height: 98px;
  background: url("../img/99.png") no-repeat;
  z-index: -1;
}

.about-us-section .testimonial-quote .feedback {
  color: #727475;
}

.about-us-section .testimonial-quote .feedback a {
  color: #d55342;
  text-decoration: underline;
  font-weight: 700;
}

@media (max-width: 585px) {
  .about-us-section .testimonial-quote .feedback a {
    font-size: 14px;
  }
}

.about-us-section .testimonial-quote .user-info {
  margin-top: 15px;
}

.about-us-section .testimonial-quote .user-info .profile-img {
  background-color: #e9e9e9;
  width: 40px;
  height: 40px;
  float: left;
  overflow: hidden;
  margin-right: 18px;
}

.about-us-section .testimonial-quote .user-info span {
  color: #202426;
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0 0.3px;
}

@media (max-width: 585px) {
  .about-us-section .testimonial-quote .user-info span {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .checked-list {
    margin-left: 30px;
  }
}

@media (max-width: 767px) {
  .checked-list {
    margin-left: 0;
  }
}

.checked-list li {
  font-size: 16px;
  color: #727475;
  font-weight: 700;
  margin-top: 10px;
  padding-left: 40px;
}

.checked-list li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  color: #00baa3;
}

.about_left_content {
  margin-top: -60px;
}

@media (max-width: 991px) {
  .about_left_content {
    margin-top: 0;
    padding-top: 40px;
  }
  .about_left_content a {
    margin-left: 30px;
  }
}

.about_left_content p {
  margin-top: 30px;
}

.about_left_content .section-title span {
  color: #d55342;
}

.about_left_content .section-title h1 span {
  color: #00baa3;
}

.about-section .org-logo-wrap {
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}

.about-section .org-logo-wrap::before {
  border-style: solid;
  border-width: 2px;
  border-color: #fafafa;
  border-radius: 50%;
  width: 348px;
  height: 348px;
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.about-section .org-logo-wrap::after {
  border-style: solid;
  border-width: 2px;
  border-color: #fafafa;
  border-radius: 50%;
  width: 261px;
  height: 261px;
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.about-section .org-logo-wrap .org-logo {
  padding: 42px 50px;
  display: inline-block;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(183, 186, 187, 0.1);
          box-shadow: 0px 16px 32px 0px rgba(183, 186, 187, 0.1);
}

.about-section .single-about-img {
  height: 400px;
  width: 100%;
  margin-top: 20px;
}

.about-section .section-title > span {
  color: #d55342;
}

.about-section .section-title h1 span {
  color: #00baa3;
}

@media (max-width: 585px) {
  .about-section .section-title h1 {
    font-size: 26px;
    line-height: 1.4;
  }
}

.timeline-wrap {
  overflow: hidden;
}

.timeline-wrap .featured-timeline .timeline__content {
  border: none;
  border-radius: 0px;
  padding: 0px;
  text-align: center;
}

.timeline-wrap .featured-timeline .timeline__content h2, .timeline-wrap .featured-timeline .timeline__content p {
  display: inline-block;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.timeline-wrap .featured-timeline .timeline__content p {
  padding-left: 5px;
}

.timeline-wrap .featured-timeline .timeline__content img {
  width: 100%;
}

.skills-section .section-title span {
  color: #d55342;
}

.skills-section .single-skill {
  margin-top: 50px;
}

.skills-section .single-skill p {
  font-size: 18px;
}

.skills-section .single-skill:nth-child(2) .skillprogress span {
  color: #00baa3;
}

.skills-section .single-skill .skillprogress {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 180px;
  text-align: center;
  margin-bottom: 20px;
}

.skills-section .single-skill .skillprogress canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.skills-section .single-skill .skillprogress .percent {
  display: block;
  line-height: 180px;
  z-index: 2;
}

.skills-section .single-skill .skillprogress .percent::after {
  content: '%';
  margin-left: 0.1em;
  font-size: .6em;
}

.skills-section .single-skill .skillprogress span {
  font-weight: 700;
  font-size: 60px;
  color: #d55342;
  font-family: "Poppins", sans-serif;
}

.skills-section .skill-box-items .col-12:nth-child(2) {
  margin-top: 30px;
}

.skills-section .skill-box-items .col-12:nth-child(4) {
  margin-top: 30px;
}

@media (max-width: 576px) {
  .skills-section .skill-box-items .col-12:nth-child(3) {
    margin-top: 30px;
  }
}

.skills-section .skill-box-items .single-skill-box {
  height: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 50px;
}

.skills-section .skill-box-items .single-skill-box:hover:before {
  opacity: .90;
  visibility: visible;
}

.skills-section .skill-box-items .single-skill-box:hover .skill-content {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.skills-section .skill-box-items .single-skill-box::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #00baa3;
  opacity: 0;
  visibility: hidden;
  left: 0;
  top: 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.skills-section .skill-box-items .single-skill-box .skill-content {
  position: relative;
  z-index: 9;
  margin-top: 40px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.skills-section .skill-box-items .single-skill-box .skill-content .icon {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  font-size: 40px;
  line-height: 100px;
  display: inline-block;
  margin: 0 auto;
  background-color: #fff;
  color: #00baa3;
  margin-bottom: 20px;
}

.skills-section .skill-box-items .single-skill-box .skill-content h3 {
  margin-bottom: 8px;
  color: #fff;
}

.skills-section .skill-box-items .single-skill-box .skill-content p {
  color: #fff;
}

.brands-carousel-section.bg-overlay:before {
  background: #efc94c;
  opacity: .90;
}

.about-wrapper .block-contents h1 {
  color: #001659;
  font-size: 56px;
}

@media (max-width: 1199px) {
  .about-wrapper .block-contents h1 {
    font-size: 40px;
    line-height: 130%;
  }
}

@media (max-width: 767px) {
  .about-wrapper .block-contents h1 {
    font-size: 32px;
  }
}

.about-wrapper .block-contents h4 {
  border-color: #ff5e14;
  font-weight: 400;
}

@media (max-width: 1199px) {
  .about-wrapper .block-contents h4 {
    padding: 5px 15px;
    font-size: 18px;
    margin: 20px 0px;
  }
}

.about-wrapper .block-contents span {
  display: block;
  color: #ff5e14;
}

.about-wrapper .block-contents span b {
  color: #001659;
}

.about-promo {
  display: inline-block;
  width: 520px;
  height: 614px;
  background: #cfac6e;
}

@media (max-width: 991px) {
  .about-promo {
    height: 500px;
  }
}

@media (max-width: 600px) {
  .about-promo {
    width: 80%;
    height: 400px;
  }
}

.about-promo .skill-popup-video {
  border-width: 10px;
  border-color: white;
  border-style: solid;
  border-radius: 50%;
  background-color: #bfbfbf;
  -webkit-box-shadow: 0px 10px 60px 0px #cfac6e;
          box-shadow: 0px 10px 60px 0px #cfac6e;
  width: 190px;
  height: 190px;
  z-index: 2;
  position: absolute;
  bottom: 60px;
  right: 0;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

@media (max-width: 585px) {
  .about-promo .skill-popup-video {
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
  }
}

.about-promo .video-play-btn {
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: #cfac6e;
  color: #fff;
  border-radius: 50%;
  line-height: 54px;
}

.about-promo .video-play-btn a:hover {
  color: #fff;
}

.brand-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  margin-top: 60px;
}

@media (max-width: 991px) {
  .brand-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .brand-grid {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

.brand-grid .single-brand-logo {
  border: 1px solid #eee;
  padding: 50px;
  text-align: center;
}

@media (max-width: 1199px) {
  .promo-featured-wrapper img {
    width: 70%;
  }
}

.promo-box-items .container {
  margin-top: -200px;
  z-index: 2;
  position: relative;
}

@media (max-width: 991px) {
  .promo-box-items .container {
    margin-top: -100px;
  }
}

@media (max-width: 1191px) {
  .promo-box-items h2 {
    font-size: 26px;
    line-height: 36px;
  }
}

@media (max-width: 991px) {
  .promo-box-items .subscribe-promo-box {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .promo-box-items .checkout-promo-box {
    margin-top: 30px;
  }
}

.support-promo-box .promo-bg {
  height: 210px;
  width: 100%;
}

.support-promo-box .promo-details {
  padding: 60px 30px;
  background: #00baa3;
  position: relative;
  z-index: 1;
}

@media (min-width: 991px) and (max-width: 1199px) {
  .support-promo-box .promo-details {
    padding: 50px 19px;
  }
}

@media (max-width: 500px) {
  .support-promo-box .promo-details {
    padding: 50px 30px;
  }
}

.support-promo-box .promo-details::before {
  position: absolute;
  top: 36%;
  right: 20px;
  content: "\f21e";
  font-family: "Font Awesome 5 Pro";
  font-size: 110px;
  font-weight: 300;
  opacity: 0.07;
  color: #fff;
  z-index: -1;
}

.checkout-promo-box {
  background-color: #d55342;
  padding: 60px 30px;
}

@media (min-width: 991px) and (max-width: 1199px) {
  .checkout-promo-box {
    padding: 50px 20px;
  }
}

@media (max-width: 500px) {
  .checkout-promo-box {
    padding: 50px 30px;
  }
}

.checkout-promo-box .icon {
  margin-bottom: 40px;
}

.checkout-promo-box a {
  margin-top: 30px;
}

.subscribe-promo-box {
  background-color: #efc94c;
  padding: 60px 50px;
}

@media (min-width: 991px) and (max-width: 1199px) {
  .subscribe-promo-box {
    padding: 50px 20px;
  }
}

@media (max-width: 500px) {
  .subscribe-promo-box {
    padding: 50px 30px;
  }
}

.subscribe-promo-box .icon {
  margin-bottom: 40px;
}

.subscribe-promo-box form {
  margin-top: 30px;
  position: relative;
}

.subscribe-promo-box form input {
  border: 0px;
  position: relative;
  height: 60px;
  line-height: 60px;
  border-radius: 30px;
  border: 2px solid #f5df94;
  background: transparent;
  display: block;
  font-size: 14px;
  width: 100%;
  color: #fff;
  padding-left: 30px;
}

.subscribe-promo-box form input::-webkit-input-placeholder {
  color: #fff;
}

.subscribe-promo-box form input:-ms-input-placeholder {
  color: #fff;
}

.subscribe-promo-box form input::-ms-input-placeholder {
  color: #fff;
}

.subscribe-promo-box form input::placeholder {
  color: #fff;
}

.subscribe-promo-box form button {
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  font-size: 16px;
  line-height: 60px;
  position: absolute;
  text-align: center;
  border: none;
  border-radius: 30px;
  color: #fff;
  background-color: #cfac6e;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.subscribe-promo-box form button:hover {
  background-color: #3FAD48;
}

/* ----------------------------------
  Service Section Styles
 ------------------------------------ */
.services-1 {
  padding-bottom: 147px;
}

@media (max-width: 991px) {
  .services-1 {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .services-1 {
    padding-bottom: 100px;
  }
}

.single-service-item.service-1 {
  margin-top: 60px;
  text-align: center;
  padding: 60px 35px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  background: #fff;
}

.single-service-item.service-1:hover .service-bg, .single-service-item.service-1.active .service-bg {
  opacity: 1;
  visibility: visible;
}

.single-service-item.service-1:hover .icon, .single-service-item.service-1.active .icon {
  color: #cfac6e;
  background-color: #fff;
}

.single-service-item.service-1:hover h3, .single-service-item.service-1.active h3 {
  color: #fff;
}

.single-service-item.service-1:hover a, .single-service-item.service-1.active a {
  background: #cfac6e;
  color: #000;
  width: 60%;
}

@media (max-width: 767px) {
  .single-service-item.service-1:hover a, .single-service-item.service-1.active a {
    width: 50%;
  }
}

.single-service-item.service-1:hover a span, .single-service-item.service-1.active a span {
  display: inline;
}

.single-service-item.service-1 .service-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  content: "";
  z-index: -1;
  border-radius: 10px;
  visibility: hidden;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  overflow: hidden;
}

.single-service-item.service-1 .service-bg::before {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .8;
  left: 0;
  top: 0;
  content: "";
  background-color: #000;
}

.single-service-item.service-1 .icon {
  width: 120px;
  height: 120px;
  margin: 0px auto;
  margin-bottom: 30px;
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  font-size: 48px;
  background-color: #cfac6e;
  line-height: 120px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-service-item.service-1 h3 {
  font-size: 26px;
  letter-spacing: -1px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media (max-width: 767px) {
  .single-service-item.service-1 h3 {
    font-size: 22px;
  }
}

.single-service-item.service-1 a {
  display: inline;
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 19px 25px;
  line-height: 1;
  font-weight: 700;
  text-transform: capitalize;
  color: #000;
  position: absolute;
  bottom: 0;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
}

@media (max-width: 767px) {
  .single-service-item.service-1 a {
    padding: 18px 24px;
    font-weight: 500;
  }
}

.single-service-item.service-1 a span {
  display: none;
  margin-right: 15px;
}

.our-services-wrapper .grid-area {
  position: relative;
  z-index: 2;
  height: 382px;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  grid-gap: 70px;
  text-align: center;
  margin: 0px auto;
  padding: 60px;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  background: #fff;
  margin-top: -150px;
}

@media (min-width: 1550px) {
  .our-services-wrapper .grid-area {
    max-width: 1444px !important;
  }
}

.our-services-wrapper .grid-area .single-service-box {
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  z-index: 1;
}

.our-services-wrapper .grid-area .single-service-box .icon {
  border-radius: 50%;
  background-color: #ff5e14;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(255, 94, 20, 0.2);
          box-shadow: 0px 16px 32px 0px rgba(255, 94, 20, 0.2);
  width: 140px;
  height: 140px;
  line-height: 140px;
  display: inline-block;
  margin: 0 auto;
  margin-bottom: 30px;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  position: relative;
}

.our-services-wrapper .grid-area .single-service-box .icon::before {
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;
  width: 120%;
  height: 120%;
  border-color: #ff5e14;
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.our-services-wrapper .grid-area .single-service-box h3 {
  font-size: 22px;
  color: #001659;
}

.our-services-wrapper .grid-area .single-service-box a {
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.our-services-wrapper .grid-area .single-service-box:hover {
  -webkit-transform: translateY(-35%);
          transform: translateY(-35%);
}

.our-services-wrapper .grid-area .single-service-box:hover a {
  opacity: 1;
  visibility: visible;
}

.our-services-wrapper .grid-area .single-service-box:hover .icon {
  margin-bottom: 50px;
}

.our-services-wrapper .grid-area .single-service-box:hover .icon::before {
  opacity: 1;
  visibility: visible;
}

.services-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  grid-gap: 40px;
  margin: 0 40px;
}

@media (max-width: 1320px) {
  .services-grid {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .services-grid {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    margin: 0 30px;
  }
}

@media (max-width: 500px) {
  .services-grid {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    margin: 0 15px;
  }
}

.services-grid .single-service-box {
  position: relative;
  z-index: 0;
  height: 420px;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .services-grid .single-service-box {
    height: 350px;
  }
}

.services-grid .single-service-box::before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "";
  opacity: .6;
  background-color: #001248;
  z-index: -1;
}

.services-grid .single-service-box:hover .hover-flip-content, .services-grid .single-service-box.active .hover-flip-content {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.services-grid .single-service-box .service-title {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  padding: 0px 30px;
  text-align: center;
}

.services-grid .single-service-box .service-title h2 {
  color: #fff;
}

.services-grid .single-service-box .hover-flip-content {
  position: absolute;
  left: -200px;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #fff;
  padding: 50px;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  z-index: 1;
}

@media (max-width: 1199px) {
  .services-grid .single-service-box .hover-flip-content {
    padding: 30px;
  }
}

.services-grid .single-service-box .hover-flip-content .icon {
  margin-bottom: 20px;
}

.services-grid .single-service-box .hover-flip-content .icon h1 {
  font-size: 100px;
  line-height: 1;
  color: #001248;
  -webkit-text-fill-color: white;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #001248;
}

.services-grid .single-service-box .hover-flip-content h2 {
  font-size: 28px;
  line-height: 120%;
  margin-bottom: 20px;
}

.appointment-notice-board .notice-board-banner {
  width: 100%;
  height: 260px;
  background-color: #eee;
  background-size: cover;
  margin-bottom: 40px;
}

.appointment-notice-board .appointment-list h2 {
  color: #fff;
  font-size: 32px;
  margin-bottom: 10px;
}

.appointment-notice-board .appointment-list .appointment-time {
  margin-top: 20px;
}

.appointment-notice-board .appointment-list .appointment-time span {
  font-weight: 600;
  color: #a1aac3;
}

.appointment-notice-board .appointment-list .appointment-time a {
  display: inline-block;
  padding: 0px 25px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  height: 30px;
  background-color: #cfac6e;
  color: #001248;
  -webkit-clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
  clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
}

.appointment-notice-board .appointment-list .appointment-time a i {
  margin-right: 3px;
}

.process-setps {
  overflow: hidden;
}

.process-setps .single-process-item {
  overflow: hidden;
  padding-right: 80px;
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 585px) {
  .process-setps .single-process-item {
    padding-right: 0;
    margin-top: 30px;
    display: block;
  }
}

.process-setps .single-process-item:nth-child(1) .setp-number {
  margin-right: 40px;
}

.process-setps .single-process-item .setp-number {
  float: left;
  margin-right: 25px;
}

@media (max-width: 585px) {
  .process-setps .single-process-item .setp-number {
    margin-right: 0;
    float: none;
    margin-bottom: 20px;
  }
}

.process-setps .single-process-item .setp-number h1 {
  font-size: 100px;
  line-height: 1;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #cfac6e;
  color: transparent;
}

@media (max-width: 991px) {
  .process-setps .single-process-item .setp-number h1 {
    font-size: 80px;
  }
}

@media (max-width: 767px) {
  .process-setps .single-process-item .setp-number h1 {
    font-size: 60px;
  }
}

.process-setps .single-process-item .content {
  overflow: auto;
}

.process-setps .single-process-item .content h3 {
  margin-bottom: 5px;
  margin-top: -3px;
}

@media (max-width: 767px) {
  .process-setps .single-process-item .content h3 {
    font-size: 22px;
  }
}

.service-gallery {
  margin-bottom: 30px;
}

.service-gallery .single-service-photo {
  width: 100%;
  height: 450px;
  background-color: #eee;
}

@media (max-width: 1199px) {
  .service-gallery .single-service-photo {
    height: 400px;
  }
}

@media (max-width: 991px) {
  .service-gallery .single-service-photo {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .service-gallery .single-service-photo {
    height: 250px;
  }
}

.service-details-wrapper h2 {
  font-size: 32px;
}

@media (max-width: 767px) {
  .service-details-wrapper h2 {
    font-size: 28px;
  }
}

@media (max-width: 500px) {
  .service-details-wrapper h2 {
    font-size: 26px;
    line-height: 140%;
  }
}

.service-details-wrapper .owl-theme .owl-nav div {
  left: 0;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #FFF;
  background-color: #cfac6e;
  margin: 0;
  padding: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 20px;
  display: inline-block;
  border-radius: 0px;
}

.service-details-wrapper .owl-theme .owl-nav div:hover {
  background-color: #001659;
  color: #fff;
}

.service-details-wrapper .owl-theme .owl-nav div.owl-next {
  left: auto;
  right: 0;
}

.service-details-wrapper .owl-theme .owl-dots {
  position: absolute;
  margin: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
}

.service-details-wrapper .owl-theme .owl-dots div.active span {
  background: #cfac6e;
}

.service-details-wrapper .owl-theme .owl-dots div span {
  background: #fff;
}

.service-details-wrapper .owl-theme .owl-dots div span:hover {
  background-color: #cfac6e;
  color: #fff;
}

.service-details-wrapper .service-details-content-wrapper h1, .service-details-wrapper .service-details-content-wrapper h2, .service-details-wrapper .service-details-content-wrapper h3, .service-details-wrapper .service-details-content-wrapper h4, .service-details-wrapper .service-details-content-wrapper h5, .service-details-wrapper .service-details-content-wrapper h6, .service-details-wrapper .service-details-content-wrapper p {
  padding-bottom: 20px;
}

.service-details-wrapper .service-details-content-wrapper img {
  margin-bottom: 20px;
}

.service-details-wrapper .service-details-content-wrapper blockquote, .service-details-wrapper .service-details-content-wrapper .wp-block-quote {
  background-color: #001659;
  color: #fff;
  font-size: 26px;
  line-height: 1.4;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 60px;
  text-align: left;
  margin: 40px 0px;
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .service-details-wrapper .service-details-content-wrapper blockquote, .service-details-wrapper .service-details-content-wrapper .wp-block-quote {
    padding: 30px;
    font-size: 22px;
    line-height: 1.5;
    margin: 20px 0px;
  }
}

@media (max-width: 767px) {
  .service-details-wrapper .service-details-content-wrapper blockquote, .service-details-wrapper .service-details-content-wrapper .wp-block-quote {
    padding: 30px 15px;
    font-size: 18px;
  }
}

.service-details-wrapper .service-details-content-wrapper blockquote::before, .service-details-wrapper .service-details-content-wrapper .wp-block-quote::before {
  right: 30px;
  font-size: 110px;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  content: "\f10d";
  bottom: -20px;
  color: #fff;
  z-index: -1;
  opacity: .1;
  font-weight: 900;
}

.service-details-wrapper .faq-accordion {
  padding-top: 10px;
}

.service-details-wrapper .faq-accordion .card {
  background-color: #cfac6e;
}

.service-details-wrapper .faq-accordion .card-header a:hover {
  color: #FFF;
}

.service-details-wrapper .faq-accordion .card-header a::after {
  color: #FFF;
}

.service-details-sidebar .single-service-sidebar {
  margin-bottom: 40px;
}

.service-details-sidebar .single-service-sidebar:last-child {
  margin-bottom: 0;
}

.service-details-sidebar .single-service-sidebar .sidebar-title {
  margin-bottom: 20px;
}

.service-details-sidebar .single-service-sidebar .sidebar-title h3 {
  text-transform: capitalize;
  margin-top: -7px;
}

.service-details-sidebar .single-service-sidebar ul li {
  position: relative;
}

.service-details-sidebar .single-service-sidebar ul li a {
  position: relative;
  display: block;
  background-color: #cfac6e;
  color: #000;
  text-align: center;
  padding: 15px 20px;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 10px;
}

.service-details-sidebar .single-service-sidebar ul li a:hover {
  background-color: #001659;
  color: #fff;
}

.service-details-sidebar .single-service-sidebar .brochures-download a {
  display: block;
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
}

.service-details-sidebar .single-service-sidebar .brochures-download a i {
  margin: 0;
  margin-right: 10px;
  display: inline-block;
}

.service-details-sidebar .single-service-sidebar .brochures-download a:first-child {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.service-details-sidebar .single-service-sidebar .brochures-download a:last-child {
  background-color: #001659;
  border-color: #001659;
  color: #fff;
}

.service-details-sidebar .single-service-sidebar .contact-us .single-contact-info {
  overflow: hidden;
  margin-bottom: 20px;
}

.service-details-sidebar .single-service-sidebar .contact-us .single-contact-info:last-child {
  margin-bottom: 0;
}

.service-details-sidebar .single-service-sidebar .contact-us .single-contact-info .icon {
  float: left;
  margin-right: 15px;
  overflow: hidden;
}

.service-details-sidebar .single-service-sidebar .contact-us .single-contact-info .contact-info {
  overflow: hidden;
}

.service-details-sidebar .single-service-sidebar .contact-us .single-contact-info .contact-info p {
  font-weight: 600;
  color: #001248;
  font-size: 17px;
}

.testimonial-nav a {
  background: #fafafa;
  display: block;
  margin-top: 30px;
  border-radius: 0px !important;
  padding: 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media (max-width: 767px) {
  .testimonial-nav a {
    padding: 20px 30px;
  }
}

.testimonial-nav a.active {
  background-color: #fff !important;
  color: #ff5e14 !important;
  border-right: 4px solid #ff5e14;
}

.testimonial-nav a .single-client-tab .profile-img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #eee;
  float: left;
  overflow: hidden;
  margin-right: 20px;
  text-align: center;
  background-position: center;
  background-size: cover;
}

.testimonial-nav a .single-client-tab .client-info {
  overflow: auto;
  padding-top: 5px;
}

.testimonial-nav a .single-client-tab .client-info h3 {
  font-size: 18px;
  line-height: 24px;
  color: #001659;
}

.testimonial-contents .tab-pane {
  margin-top: 30px;
}

.testimonial-contents .tab-pane .single-testimonial-content .engginer-img {
  border-style: solid;
  border-width: 10px;
  border-color: #fff;
  background-color: #eee;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.04);
  width: 330px;
  height: 415px;
  overflow: hidden;
  float: left;
  margin-right: 50px;
}

@media (max-width: 767px) {
  .testimonial-contents .tab-pane .single-testimonial-content .engginer-img {
    width: 250px;
    height: 280px;
    float: inherit;
    display: block;
    margin: 0 auto;
  }
}

.testimonial-contents .tab-pane .single-testimonial-content .engginer-img img {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .testimonial-contents .tab-pane .single-testimonial-content .engginer-img img {
    margin-top: 0;
  }
}

.testimonial-contents .tab-pane .single-testimonial-content .content {
  overflow: auto;
  padding-top: 60px;
}

@media (max-width: 767px) {
  .testimonial-contents .tab-pane .single-testimonial-content .content {
    width: 100%;
    text-align: center;
    padding-top: 30px;
  }
}

.testimonial-contents .tab-pane .single-testimonial-content .content .rating-star {
  margin-bottom: 15px;
}

.testimonial-contents .tab-pane .single-testimonial-content .content .rating-star i, .testimonial-contents .tab-pane .single-testimonial-content .content .rating-star span {
  color: #ffa200;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.testimonial-contents .tab-pane .single-testimonial-content .content h3 {
  font-size: 22px;
  line-height: 150%;
  font-weight: 500;
  color: #001659;
}

@media (max-width: 767px) {
  .testimonial-contents .tab-pane .single-testimonial-content .content h3 {
    font-size: 20px;
  }
}

.testimonial-contents .tab-pane .single-testimonial-content .content .theme-btn {
  margin-top: 35px;
}

.brand-logo-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.project-wrapper {
  position: relative;
}

.project-wrapper .portfolio-carousel-active .single-project {
  overflow: hidden;
}

.project-wrapper .portfolio-carousel-active .single-project .project-contents {
  background-color: #f7f7f7;
  border-radius: 10px;
  position: relative;
  margin-top: 60px;
}

.project-wrapper .portfolio-carousel-active .single-project .project-details {
  padding: 100px 0px;
}

@media (max-width: 991px) {
  .project-wrapper .portfolio-carousel-active .single-project .project-details {
    padding: 50px;
  }
}

.project-wrapper .portfolio-carousel-active .single-project .project-details .project-meta .project-cat {
  margin-right: 30px;
}

.project-wrapper .portfolio-carousel-active .single-project .project-details .project-meta .client-info span {
  color: #000;
  font-weight: 600;
  padding-right: 5px;
}

.project-wrapper .portfolio-carousel-active .single-project .project-details .project-meta .client-info i {
  color: #000;
  font-weight: 500;
}

.project-wrapper .portfolio-carousel-active .single-project .project-details h2 {
  font-size: 40px;
  line-height: 50px;
  margin-top: 5px;
}

@media (max-width: 1199px) {
  .project-wrapper .portfolio-carousel-active .single-project .project-details h2 {
    font-size: 32px;
    line-height: 130%;
  }
}

@media (max-width: 767px) {
  .project-wrapper .portfolio-carousel-active .single-project .project-details h2 {
    font-size: 30px;
  }
}

.project-wrapper .portfolio-carousel-active .single-project .project-details p {
  margin-top: 15px;
}

.project-wrapper .portfolio-carousel-active .single-project .project-details .read-btn {
  margin-top: 35px;
}

.project-wrapper .portfolio-carousel-active .single-project .project-thumbnail {
  position: relative;
  height: 512px;
  background-color: #dddddd;
  border-radius: 10px;
  margin-top: -60px;
  overflow: hidden;
  background-size: cover;
}

@media (max-width: 991px) {
  .project-wrapper .portfolio-carousel-active .single-project .project-thumbnail {
    border-radius: 0px;
    height: 300px;
    background-position: center;
  }
}

@media (max-width: 767px) {
  .project-wrapper .portfolio-carousel-active .single-project .project-thumbnail {
    height: 250px;
  }
}

.project-wrapper .portfolio-carousel-active .single-project .project-thumbnail .popup-gallery {
  display: block;
  width: 100%;
  height: 100%;
}

.project-wrapper .portfolio-carousel-active .single-project .project-thumbnail .popup-gallery img {
  max-width: 100%;
  width: 100%;
}

.project-wrapper .project-carousel-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 95px;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  border-radius: 10px;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

@media (max-width: 991px) {
  .project-wrapper .project-carousel-nav {
    right: 50px;
  }
}

.project-wrapper .project-carousel-nav div {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 24px;
  color: #fff;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  background: #cfac6e;
  cursor: pointer;
}

@media (max-width: 991px) {
  .project-wrapper .project-carousel-nav div {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
  }
}

.project-wrapper .project-carousel-nav div.owl-next {
  background: #febc35;
}

.project-details-wrapper {
  position: relative;
}

.project-details-wrapper .project-thumb {
  height: 600px;
  width: 100%;
  position: relative;
}

@media (max-width: 1199px) {
  .project-details-wrapper .project-thumb {
    height: 400px;
  }
}

@media (max-width: 991px) {
  .project-details-wrapper .project-thumb {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .project-details-wrapper .project-thumb {
    height: 280px;
  }
}

.project-details-wrapper .project-meta-data {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  grid-gap: 20px;
  width: 95%;
  margin: 0 auto;
  background-color: #001659;
  padding: 30px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: relative;
}

@media (max-width: 1199px) {
  .project-details-wrapper .project-meta-data h3 {
    font-size: 20px;
  }
  .project-details-wrapper .project-meta-data span {
    font-size: 15px;
  }
  .project-details-wrapper .project-meta-data .theme-btn {
    padding: 16px 25px;
    margin: 0 auto;
    margin-top: 20px;
  }
}

@media (max-width: 991px) {
  .project-details-wrapper .project-meta-data {
    text-align: center;
    -webkit-transform: translateY(-40%);
            transform: translateY(-40%);
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
    margin-bottom: -50px;
  }
}

@media (max-width: 500px) {
  .project-details-wrapper .project-meta-data {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
    padding: 20px;
    grid-gap: 10px;
  }
}

.project-details-wrapper .project-meta-data .theme-btn {
  display: block;
  text-align: center;
}

.project-details-wrapper .project-meta-data .project-info h3 {
  color: #fff;
}

.project-details-wrapper p {
  margin-top: 20px;
  line-height: 30px;
}

.project-details-wrapper p:nth-child(1)::first-letter {
  font-size: 50px;
  color: #cfac6e;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(183, 186, 187, 0.2);
          box-shadow: 0px 16px 32px 0px rgba(183, 186, 187, 0.2);
  padding: 10px 25px;
  line-height: 1.5;
  margin-right: 15px;
  float: left;
  overflow: hidden;
  font-weight: 700;
}

.project-details-wrapper .project-details-content h1, .project-details-wrapper .project-details-content h2, .project-details-wrapper .project-details-content h3, .project-details-wrapper .project-details-content h4, .project-details-wrapper .project-details-content h5 {
  margin-top: 30px;
}

.project-details-wrapper .project-details-content img {
  margin-top: 30px;
}

.project-details-wrapper .single-process-box {
  text-align: left;
  margin-top: 30px;
}

.project-details-wrapper .single-process-box .icon {
  text-align: center;
  border-radius: 50%;
  background-color: white;
  -webkit-box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.06);
  width: 80px;
  height: 80px;
  line-height: 80px;
  position: relative;
  display: inline-block;
}

.project-details-wrapper .single-process-box .icon span {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background-color: #cfac6e;
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
}

.project-details-wrapper .single-process-box .content {
  margin-top: 40px;
}

.portfolio-cat-filter.style-2 button {
  background-color: #fff;
}

.portfolio-cat-filter button {
  border: none;
  background-color: #f6f6f6;
  display: inline-block;
  padding: 15px 35px;
  line-height: 1;
  text-transform: capitalize;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  border-radius: 30px;
  margin: 0px 3px;
  font-weight: 600;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .portfolio-cat-filter button {
    padding: 15px 25px;
    font-size: 12px;
    margin-top: 10px;
  }
}

.portfolio-cat-filter button.active, .portfolio-cat-filter button:hover {
  color: #fff;
  background-color: #ff5e14;
}

.portfolio-carousel-wrapper.owl-carousel {
  z-index: 0;
}

.portfolio-carousel-wrapper .center .single-project-item .project-details {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.portfolio-carousel-wrapper .single-project-item {
  padding-bottom: 30px;
  margin-top: 30px;
}

.portfolio-carousel-wrapper .single-project-item:hover .project-details {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.portfolio-carousel-wrapper .single-project-item .project-thumb {
  height: 450px;
  background-color: #eee;
  position: relative;
}

@media (max-width: 991px) {
  .portfolio-carousel-wrapper .single-project-item .project-thumb {
    height: 350px;
  }
}

.portfolio-carousel-wrapper .single-project-item .project-details {
  position: relative;
  background-color: #fff;
  width: 90%;
  margin: 0px auto;
  z-index: 3;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  padding: 25px 35px;
  margin-top: -60px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}

.portfolio-carousel-wrapper .single-project-item .project-details::before {
  position: absolute;
  width: 90%;
  left: 50%;
  bottom: -10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #cfac6e;
  z-index: -1;
  content: "";
  height: 10px;
}

.portfolio-carousel-wrapper .single-project-item .project-details .icon {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 0;
  padding: 10px 30px;
  background-color: #cfac6e;
  color: #001248;
  text-align: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.portfolio-carousel-wrapper .single-project-item .project-details .meta span:last-child {
  color: #001248;
  font-weight: 600;
}

.portfolio-carousel-wrapper .single-project-item .project-details h3 {
  color: #001248;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.portfolio-carousel-wrapper .single-project-item .project-details h3:hover {
  color: #cfac6e;
}

.portfolio-wrapper-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.portfolio-wrapper-section::before {
  position: absolute;
  width: 150%;
  height: 60%;
  left: -10px;
  bottom: -20%;
  content: "";
  background-color: #f6f6f6;
  -webkit-transform: rotate(-11deg);
          transform: rotate(-11deg);
  z-index: -1;
}

@media (max-width: 991px) {
  .portfolio-wrapper-section::before {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    bottom: 0;
    left: 0;
    height: 40%;
    width: 100%;
  }
}

.single-cause-item {
  margin-top: 30px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-cause-item:hover .cause-bg::before {
  background-color: #ff5e14;
}

.single-cause-item:hover .cause-bg::after {
  background-color: #e24700;
}

.single-cause-item:hover .cause-bg .icon {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.single-cause-item:hover .cause-content {
  background-color: #ff5e14;
}

.single-cause-item:hover .cause-content .cause-meta {
  color: #fff;
}

.single-cause-item .cause-bg {
  height: 250px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.single-cause-item .cause-bg::before {
  position: absolute;
  width: 200%;
  height: 90px;
  left: -10px;
  bottom: -10px;
  content: "";
  background-color: #001659;
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg);
  z-index: 1;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media (max-width: 991px) {
  .single-cause-item .cause-bg::before {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }
}

@media (max-width: 767px) {
  .single-cause-item .cause-bg::before {
    display: none;
  }
}

.single-cause-item .cause-bg::after {
  position: absolute;
  width: 200%;
  height: 107px;
  left: -22px;
  bottom: -10px;
  content: "";
  background-color: #ff5e14;
  -webkit-transform: rotate(-19deg);
          transform: rotate(-19deg);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media (max-width: 991px) {
  .single-cause-item .cause-bg::after {
    -webkit-transform: rotate(-11deg);
            transform: rotate(-11deg);
  }
}

@media (max-width: 767px) {
  .single-cause-item .cause-bg::after {
    display: none;
  }
}

.single-cause-item .cause-bg .icon {
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #001659;
  z-index: 3;
  right: 30px;
  bottom: 0;
  content: "";
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(115%);
          transform: translateY(115%);
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.single-cause-item .cause-content {
  padding: 20px 35px 30px;
  background-color: #001659;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-cause-item .cause-content .cause-meta {
  color: #a5adc6;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-cause-item .cause-content h4 {
  color: #fff;
}

.single-approch-card,
.single-service-card {
  padding: 15px;
  background-color: #fff;
  position: relative;
  -webkit-box-shadow: 0px 30px 60px 0px rgba(52, 52, 52, 0.04);
          box-shadow: 0px 30px 60px 0px rgba(52, 52, 52, 0.04);
  margin-top: 30px;
}

.single-approch-card .card-thumb,
.single-service-card .card-thumb {
  height: 200px;
  position: relative;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-approch-card .content,
.single-service-card .content {
  padding: 30px 35px;
  background-color: #f7f7f7;
  position: relative;
}

@media (max-width: 600px) {
  .single-approch-card .content,
  .single-service-card .content {
    padding: 30px;
  }
}

.single-approch-card .content .case-cat,
.single-service-card .content .case-cat {
  position: absolute;
  right: 0;
  bottom: 100%;
}

.single-approch-card .content .case-cat a,
.single-service-card .content .case-cat a {
  display: inline-block;
  padding: 20px;
  line-height: 1;
  font-size: 24px;
  background-color: #cfac6e;
  position: relative;
}

.single-approch-card .content .case-cat a:hover,
.single-service-card .content .case-cat a:hover {
  color: #fff;
  background: #ff5e14;
}

.single-approch-card .content h3,
.single-service-card .content h3 {
  font-size: 22px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.single-approch-card .content h3:hover a,
.single-service-card .content h3:hover a {
  color: #cfac6e;
}

.single-approch-card .content .read-btn,
.single-service-card .content .read-btn {
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  margin-top: 20px;
  display: block;
}

.single-approch-card .content .read-btn:hover,
.single-service-card .content .read-btn:hover {
  color: #cfac6e;
  padding-left: 5px;
}

.single-approch-card .content .read-btn i,
.single-service-card .content .read-btn i {
  margin-left: 5px;
}

.single-pricing-table {
  position: relative;
  z-index: 1;
  padding: 70px 60px;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .single-pricing-table {
    padding: 50px 40px;
  }
}

.single-pricing-table:hover::before, .single-pricing-table.active::before {
  opacity: .9;
  background: #000;
}

.single-pricing-table:hover h3, .single-pricing-table.active h3 {
  color: #fff;
}

.single-pricing-table:hover li, .single-pricing-table.active li {
  color: #c8c8c8;
}

.single-pricing-table:hover .theme-btn, .single-pricing-table.active .theme-btn {
  background-color: #cfac6e;
  border-color: #cfac6e;
}

.single-pricing-table::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  background-color: #fff;
  z-index: -1;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-pricing-table .price {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: #cfac6e;
}

.single-pricing-table .price span {
  font-size: 100px;
}

@media (max-width: 767px) {
  .single-pricing-table .price span {
    font-size: 80px;
  }
}

.single-pricing-table .package-name {
  margin-top: 15px;
}

.single-pricing-table .package-name h3 {
  text-transform: capitalize;
}

.single-pricing-table .package-name span {
  text-transform: capitalize;
}

.single-pricing-table ul {
  margin: 40px 0px;
}

.single-pricing-table ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 7px;
  display: block;
}

.single-pricing-table ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  color: #cfac6e;
}

.single-pricing-table .package-btn .theme-btn {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  width: 100%;
  text-align: center;
}

.single-package-plan {
  padding: 50px 55px 60px;
  background-color: #fff;
  margin-top: 30px;
  position: relative;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-package-plan::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #ff5e14;
  content: "";
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.single-package-plan:hover::before, .single-package-plan.active::before {
  height: 4px;
  opacity: 1;
  visibility: visible;
}

.single-package-plan:hover li::before, .single-package-plan.active li::before {
  color: #ff5e14;
}

.single-package-plan .plan-name {
  margin-bottom: 30px;
}

.single-package-plan .plan-name h3 {
  margin-bottom: 10px;
}

.single-package-plan .plan-name .price {
  margin-right: 20px;
}

.single-package-plan .plan-name .price h1 {
  font-size: 70px;
  line-height: 1;
}

.single-package-plan .plan-name .plan-time {
  font-size: 15px;
  line-height: 26px;
}

.single-package-plan .package-features ul li {
  position: relative;
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
}

.single-package-plan .package-features ul li::before {
  position: absolute;
  right: 0;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-package-plan .but-btn {
  margin-top: 40px;
}

.single-package-plan .but-btn .theme-btn {
  display: block;
  text-align: center;
}

.single-package-plan .but-btn .theme-btn i {
  margin-left: 15px;
}

/* ----------------------------------
	Call To Action Section Styles
 ------------------------------------ */
.cta-wrapper {
  position: relative;
  z-index: 1;
}

.cta-wrapper::before {
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
  top: 0;
  content: "";
  z-index: -1;
  background-color: #f6f6f6;
}

.cta-funfact-wrap {
  overflow: hidden;
}

.cta-content {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  padding: 30px;
  color: #000;
}

@media (max-width: 767px) {
  .cta-content {
    padding: 30px 15px;
  }
}

.cta-content.cta-style-2 {
  border-radius: 0px;
}

.cta-content.cta-style-2::before {
  background: #ff5e14;
  opacity: .95;
}

.cta-content.cta-style-2 i {
  color: #ff5e14;
}

.cta-content.cta-style-2 a, .cta-content.cta-style-2 h1, .cta-content.cta-style-2 h2, .cta-content.cta-style-2 p, .cta-content.cta-style-2 span {
  color: #fff !important;
}

.cta-content .contact-info {
  display: inline-block;
}

.cta-content::before {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .9;
  left: 0;
  top: 0;
  content: "";
  background-color: #cfac6e;
}

.cta-content h1 {
  font-size: 34px;
  line-height: 130%;
}

@media (max-width: 767px) {
  .cta-content h1 {
    font-size: 30px;
    line-height: 150%;
    letter-spacing: -1px;
  }
}

.cta-content .icon {
  width: 60px;
  height: 60px;
  margin: 0px auto;
  border-radius: 50%;
  font-size: 28px;
  text-align: center;
  background-color: #FFF;
  line-height: 60px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  overflow: hidden;
  clear: both;
  margin-right: 20px;
  float: left;
}

@media (max-width: 991px) {
  .cta-content .icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    margin-right: 10px;
  }
}

.cta-content .contact-number {
  overflow: auto;
}

.cta-content .contact-number a {
  font-weight: 700;
  font-size: 20px;
  color: #000;
}

.cta-content .contact-number span {
  display: block;
}

@media (max-width: 1199px) {
  .cta-content .cta-heading {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .cta-content .cta-heading {
    font-size: 32px;
    line-height: 145%;
  }
}

.video-section::before {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  background: #cfac6e;
  width: 100%;
  height: 100%;
  opacity: .7;
  z-index: -1;
}

.video-section .section-title span {
  color: #00baa3;
}

.video-section .section-title h1 {
  color: #fff;
}

.subscribe-box-wrapper .subscribe_left_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 40%;
  height: 100%;
  content: "";
  background-color: #f6f6f6;
}

.subscribe-box-wrapper .cta-contents .icon {
  width: 100px;
  height: 100px;
  border-radius: 30px;
  color: #fff;
  font-size: 50px;
  line-height: 100px;
  text-align: center;
  background-color: #cfac6e;
  margin-bottom: 40px;
}

@media (max-width: 1199px) {
  .subscribe-box-wrapper .cta-contents .icon {
    margin: 0 auto;
    margin-bottom: 30px;
  }
}

.subscribe-box-wrapper .cta-contents p {
  margin-top: 25px;
  color: #cfcdcc;
  font-size: 20px;
}

.subscribe-box-wrapper .subscribe-form form {
  height: 74px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 55px;
}

.subscribe-box-wrapper .subscribe-form form input {
  border: 0px;
  width: 70%;
  position: relative;
  border-style: solid;
  border-width: 2px;
  border-right-width: 0px;
  border-color: #353839;
  border-radius: 10px 0px 0px 10px;
  background-color: rgba(255, 255, 255, 0.059);
  padding-left: 35px;
  padding-right: 15px;
  line-height: 1;
  color: #fff;
}

.subscribe-box-wrapper .subscribe-form form input::-webkit-input-placeholder {
  color: #999;
}

.subscribe-box-wrapper .subscribe-form form input:-ms-input-placeholder {
  color: #999;
}

.subscribe-box-wrapper .subscribe-form form input::-ms-input-placeholder {
  color: #999;
}

.subscribe-box-wrapper .subscribe-form form input::placeholder {
  color: #999;
}

.subscribe-box-wrapper .subscribe-form form button {
  width: 30%;
  height: 100%;
  background: #cfac6e;
  color: #000;
  display: inline-block;
  border-radius: 0px 10px 10px 0px;
  font-size: 16px;
  line-height: 1;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  font-weight: 700;
}

.subscribe-box-wrapper .subscribe-form form button:hover {
  color: #fff;
}

.cta-funfact-wrap {
  background: #f6f6f6;
}

@media (max-width: 991px) {
  .cta-funfact-wrap {
    background: transparent;
  }
}

.cta-funfact-wrap .fun-fact {
  background: #ff5e14;
  height: 140px;
}

.cta-funfact-wrap .fun-fact span {
  font-size: 80px;
  line-height: 1;
  font-weight: 700;
  margin: 0 10px;
}

.cta-funfact-wrap .cta-wrap {
  position: relative;
  z-index: 1;
  height: 140px;
}

@media (max-width: 991px) {
  .cta-funfact-wrap .cta-wrap {
    background-color: #001659;
  }
}

@media (max-width: 767px) {
  .cta-funfact-wrap .cta-wrap {
    height: 100px;
  }
}

.cta-funfact-wrap .cta-wrap::before {
  position: absolute;
  width: 200%;
  height: 100%;
  background-color: #001659;
  top: 0;
  left: -15px;
  content: "";
  z-index: -1;
}

@media (max-width: 991px) {
  .cta-funfact-wrap .cta-wrap::before {
    display: none;
  }
}

.cta-funfact-wrap .cta-wrap h2 {
  font-size: 40px;
  line-height: 130%;
}

@media (max-width: 1191px) {
  .cta-funfact-wrap .cta-wrap h2 {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .cta-funfact-wrap .cta-wrap h2 {
    font-size: 26px;
  }
}

.cta-funfact-wrap .cta-wrap a {
  color: #ff5e14;
  text-decoration: underline;
  margin-right: -40px;
}

.cta-funfact-wrap .cta-wrap a i {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .cta-funfact-wrap .cta-wrap a {
    margin-right: 0;
  }
}

.video_wrapper {
  border-style: solid;
  border-width: 10px;
  border-color: #fff;
  border-radius: 20px;
  background-color: #eee;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  height: 475px;
}

@media (max-width: 767px) {
  .video_wrapper {
    height: 300px;
  }
}

.promo-featured-wrapper .featured-img {
  height: 556px;
  background-color: #eee;
  position: relative;
  margin-left: 60px;
  background-size: cover;
  background-position: center;
  margin-right: 60px;
}

@media (max-width: 991px) {
  .promo-featured-wrapper .featured-img {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .promo-featured-wrapper .featured-img {
    height: 300px;
  }
}

.promo-featured-wrapper .top-img {
  width: 180px;
  height: 180px;
  background-color: #eee;
  left: -60px;
  top: -40px;
  position: absolute;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.04);
          box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.04);
}

@media (max-width: 767px) {
  .promo-featured-wrapper .top-img {
    left: -30px;
  }
}

.promo-featured-wrapper .bottom-img {
  border-style: solid;
  border-width: 10px;
  border-color: white;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.04);
          box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.04);
  background-color: #eee;
  position: absolute;
  width: 269px;
  height: 269px;
  right: -60px;
  bottom: -60px;
}

@media (max-width: 767px) {
  .promo-featured-wrapper .bottom-img {
    display: none;
  }
}

.block-contents {
  padding-right: 40px;
}

@media (max-width: 1199px) {
  .block-contents {
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .block-contents {
    margin-left: 0;
  }
}

.block-contents h1 {
  margin-top: 5px;
}

.block-contents h4 {
  margin-top: 40px;
  font-weight: 600;
  padding: 5px 28px;
  margin-bottom: 30px;
  border-left: 4px solid #cfac6e;
}

@media (max-width: 767px) {
  .block-contents h4 {
    padding: 5px 20px;
    font-size: 18px;
  }
}

.block-contents .theme-btn {
  margin-top: 40px;
}

@media (max-width: 991px) {
  .block-contents .theme-btn {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .block-contents .theme-btn {
    margin-top: 20px;
  }
}

.photo-grid {
  display: -ms-grid;
  display: grid;
  grid-gap: 30px;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
}

.photo-grid .single-photo {
  background-color: #e6e6e6;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}

.photo-grid .single-photo:first-of-type {
  height: 570px;
  -ms-grid-row: 1;
      grid-row-start: 1;
  grid-row-end: 3;
}

.photo-grid .single-photo:nth-of-type(2) {
  height: 220px;
  -ms-grid-row: 1;
      grid-row-start: 1;
  grid-row-end: 2;
}

.photo-grid .single-photo:nth-of-type(3) {
  height: 320px;
}

.photo-grid .single-photo a {
  display: block;
}

.user-data {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #e6e6e6;
}

.user-data .user-img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #e6e6e6;
  float: left;
  clear: both;
  overflow: auto;
  margin-right: 20px;
}

.user-data .user-info {
  margin-right: 40px;
}

.user-data .user-info span {
  font-size: 14px;
  display: block;
  line-height: 1;
}

.user-data .phone-info a {
  font-weight: 700;
}

.user-data .phone-info span {
  font-size: 14px;
  display: block;
  line-height: 1;
}

.video-fetaures-wrapper .section-title p {
  color: #ff5e14;
}

.video-fetaures-wrapper h3, .video-fetaures-wrapper h1, .video-fetaures-wrapper h2 {
  color: #001659;
}

.features-list .single-item {
  overflow: hidden;
  margin-top: 40px;
}

.features-list .single-item .icon {
  background-color: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  float: left;
  overflow: hidden;
  margin-right: 40px;
}

@media (max-width: 767px) {
  .features-list .single-item .icon {
    float: inherit;
    display: block;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.features-list .single-item .content {
  overflow: hidden;
}

@media (max-width: 767px) {
  .features-list .single-item .content {
    display: block;
    padding-right: 50px;
  }
}

.features-list .single-item .content p {
  padding-left: 20px;
  margin-top: 15px;
  position: relative;
}

.features-list .single-item .content p::before {
  position: absolute;
  width: 2px;
  height: 42px;
  left: 0;
  top: 50%;
  content: "";
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #ff5e14;
}

.core-features-list .single-feature-item {
  overflow: hidden;
  margin-top: 50px;
}

.core-features-list .single-feature-item:hover .icon {
  background: #cfac6e;
}

.core-features-list .single-feature-item .icon {
  float: left;
  width: 100px;
  height: 80px;
  background-color: #fff;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  text-align: center;
  overflow: hidden;
  margin-right: 20px;
  line-height: 80px;
  -webkit-clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.core-features-list .single-feature-item .content {
  overflow: hidden;
}

.core-features-list .single-feature-item .content h3 {
  margin-top: -6px;
  font-size: 22px;
  margin-bottom: 5px;
}

@media (min-width: 1200px) {
  .video-featured-wrapper .container-fuild {
    max-width: 100%;
    width: 100%;
    padding: 0 120px;
  }
}

.video-wrapper .video-play-btn a {
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  border-radius: 0;
  width: 120px;
  height: 100px;
  line-height: 100px;
  background-color: #cfac6e;
  color: #001659;
}

.video-wrapper .video-play-btn a:hover {
  color: #fff;
}

.video-featured-wrapper {
  position: relative;
  z-index: 0;
}

.video_bg {
  position: absolute;
  width: 50%;
  height: 100%;
  content: "";
  background-size: cover;
  right: 0;
  top: 0;
  z-index: -1;
}

.icon-boxs {
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}

.icon-boxs .single-icon-box {
  margin-top: 30px;
  overflow: hidden;
  padding-right: 50px;
}

@media (min-width: 767px) {
  .icon-boxs .single-icon-box {
    padding-right: 0;
  }
}

.icon-boxs .single-icon-box .icon {
  float: left;
  border-radius: 50%;
  background-color: #cfac6e;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  color: #413939;
  font-size: 42px;
  margin-right: 25px;
}

@media (max-width: 585px) {
  .icon-boxs .single-icon-box .icon {
    float: none;
    display: inline-block;
    margin-bottom: 20px;
  }
}

.icon-boxs .single-icon-box .content {
  overflow: hidden;
}

@media (max-width: 585px) {
  .icon-boxs .single-icon-box .content {
    display: block;
  }
}

.icon-boxs .single-icon-box .content p {
  margin-top: 5px;
}

.photo-style-grid {
  position: relative;
}

.photo-style-grid .top-img {
  width: 180px;
  height: 180px;
  background-color: #eee;
  margin-bottom: -120px;
  position: relative;
  z-index: 3;
}

.photo-style-grid .main-img {
  background-color: #fff;
  margin: 0px 50px;
  height: 560px;
  position: relative;
}

@media (max-width: 1199px) {
  .photo-style-grid .main-img {
    height: 450px;
  }
}

@media (max-width: 991px) {
  .photo-style-grid .main-img {
    height: 400px;
  }
}

@media (max-width: 585px) {
  .photo-style-grid .main-img {
    height: 200px;
    margin-bottom: 30px;
  }
}

.photo-style-grid .bottom-img {
  width: 280px;
  height: 280px;
  background-color: #fff;
  border: 10px solid #fff;
  position: relative;
  z-index: 3;
  float: right;
  margin-top: -190px;
  margin-right: -50px;
}

@media (max-width: 1199px) {
  .photo-style-grid .bottom-img {
    margin-right: 0;
  }
}

@media (max-width: 585px) {
  .photo-style-grid .bottom-img {
    display: none;
  }
}

.single-work-process {
  text-align: center;
  padding: 0px 30px;
  margin-top: 40px;
}

.single-work-process .icon {
  margin-bottom: 25px;
}

.single-work-process .content h4 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.single-work-process .content p {
  font-size: 16px;
}

@media (max-width: 767px) {
  .skill-bg {
    padding: 50px 0px;
  }
}

.single-team-member {
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
  position: relative;
  margin-top: 30px;
  height: 410px;
  width: 100%;
  background: #f6f6f6;
  overflow: hidden;
}

@media (max-width: 767px) {
  .single-team-member {
    height: 320px;
  }
}

.single-team-member .member-img {
  position: relative;
  text-align: center;
  margin-top: 20px;
}

.single-team-member .social-icons {
  position: absolute;
  right: 40px;
  top: 40px;
  content: "";
  overflow: hidden;
  display: inline-block;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.single-team-member .social-icons:hover .plus-icon {
  background-color: #ff5e14;
}

.single-team-member .social-icons:hover .plus-icon i {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  color: #fff;
}

.single-team-member .social-icons:hover .top {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.single-team-member .social-icons:hover .right {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.single-team-member .social-icons .plus-icon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
}

.single-team-member .social-icons .plus-icon i {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-team-member .social-icons .plus-icon, .single-team-member .social-icons a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  display: inline-block;
  background: #fff;
  color: #001659;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-team-member .social-icons .plus-icon:hover, .single-team-member .social-icons a:hover {
  background: #ff5e14;
  color: #fff;
}

.single-team-member .social-icons .top {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  margin-right: 55px;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  height: 50px;
}

.single-team-member .social-icons .top a {
  margin-right: 5px;
}

.single-team-member .social-icons .right {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  width: 50px;
  float: right;
}

.single-team-member .social-icons .right a {
  margin-top: 10px;
  display: block;
}

.single-team-member .member-details {
  position: absolute;
  bottom: 40px;
  left: 40px;
  content: "";
}

.single-team-member .member-details h3 {
  color: #001659;
  background: #fff;
  line-height: 1;
  padding: 20px 25px;
  padding-right: 50px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .single-team-member .member-details h3 {
    font-size: 20px;
    padding: 15px 25px;
  }
}

.single-team-member .member-details span {
  padding: 8px 25px;
  color: #fff;
  font-weight: 500;
  line-height: 1;
  background: #ff5e14;
  display: inline-block;
}

@media (max-width: 767px) {
  .single-team-member .member-details span {
    font-size: 14px;
    padding: 8px 16px;
  }
}

.funfact-wrap {
  background-color: #d55342;
  z-index: -1;
  margin-top: -200px;
}

@media (max-width: 991px) {
  .funfact-wrap {
    margin-top: -160px;
  }
}

@media (max-width: 767px) {
  .funfact-wrap {
    margin-top: -120px;
  }
}

.funfact-wrap .single-fact {
  margin-top: 80px;
}

@media (max-width: 991px) {
  .funfact-wrap .single-fact {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .funfact-wrap .single-fact {
    margin-top: 40px;
  }
}

.funfact-wrap .single-fact .icon {
  float: left;
  overflow: hidden;
  margin-right: 25px;
}

.funfact-wrap .single-fact .numbers {
  overflow: hidden;
}

.funfact-wrap .single-fact .numbers .digit {
  margin-top: -6px;
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.funfact-wrap .single-fact .numbers .digit::before {
  position: absolute;
  right: -10px;
  bottom: 10px;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  content: "+";
}

.funfact-wrap .single-fact .numbers p {
  position: relative;
  padding-left: 30px;
}

.funfact-wrap .single-fact .numbers p::before {
  position: absolute;
  left: 0;
  content: "---";
  color: #dd867a;
}

.single-skill-bar {
  margin-top: 30px;
}

.single-skill-bar h4 {
  margin-bottom: 5px;
}

.single-skill-bar .progress {
  height: 12px;
}

.single-skill-bar .progress .progress-bar {
  background-color: #cfac6e;
  color: #000;
}

.svg-line {
  position: absolute;
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 50%;
}

.timeline-carousel-wrapper .owl-item:nth-child(2n) {
  padding-top: 40px;
}

.timeline-carousel-wrapper .single-time-line {
  text-align: center;
  margin-top: 30px;
}

.timeline-carousel-wrapper .single-time-line h2 {
  text-transform: capitalize;
  font-size: 80px;
  line-height: 1;
  color: #fff;
  -webkit-text-stroke: 2px #ff5e14;
}

.timeline-carousel-wrapper .single-time-line .icon {
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.08);
          box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.08);
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 54px;
  color: #001659;
  margin: 30px 0px;
}

.timeline-carousel-wrapper .single-time-line .content h4 {
  margin-bottom: 15px;
}

.timeline-carousel-wrapper .single-time-line .content p {
  font-size: 15px;
  line-height: 28px;
}

.faq-section .content-block .bg-img {
  position: relative;
}

.faq-section .content-block .man {
  border-style: solid;
  border-width: 4px;
  border-color: white;
  border-radius: 50%;
  background-color: #bfbfbf;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 42px;
  height: 42px;
  top: 50px;
  left: 80px;
  -webkit-animation: bounce 3s infinite linear;
          animation: bounce 3s infinite linear;
}

.faq-section .content-block .man-2 {
  right: 140px;
  left: auto;
  top: 80px;
  -webkit-animation: bounce 5s infinite linear;
          animation: bounce 5s infinite linear;
}

.faq-section .content-block .man-3 {
  left: 25%;
  top: auto;
  bottom: 60px;
  -webkit-animation: bounce 4s infinite linear;
          animation: bounce 4s infinite linear;
}

.accordion {
  color: #777;
}

.accordion .card {
  margin-top: 10px;
  border: none;
}

.accordion .card-header {
  padding: 0px 28px;
  border: none;
  background-color: transparent;
}

@media (max-width: 480px) {
  .accordion .card-header {
    padding: 0 15px;
  }
}

.accordion .card-header a {
  display: block;
  font-weight: 600;
  line-height: 50px;
  padding: 10px 0px;
  position: relative;
  color: #000;
  font-size: 18px;
}

.accordion .card-header a:after {
  content: "\f067";
  /* <i class="fal fa-angle-down"></i> */
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 400;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #cfac6e;
}

.accordion .card-header a[aria-expanded="true"]:after {
  content: "\f068";
  /* <i class="fal fa-angle-up"></i> */
}

@media (max-width: 767px) {
  .accordion .card-header a {
    line-height: 40px;
    font-size: 15px;
  }
}

.accordion .card-header a:hover {
  color: #cfac6e;
}

.accordion .card-body {
  padding-left: 28px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.search-cause-wrap {
  margin-top: -60px;
  position: relative;
  z-index: 1;
}

.search-cause-wrap::before {
  position: absolute;
  background-color: #f0f0f0;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
}

.search-cause-wrap form {
  background-color: #202426;
  padding: 40px 60px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 991px) {
  .search-cause-wrap form {
    padding: 30px 40px;
  }
}

@media (max-width: 991px) {
  .search-cause-wrap form {
    display: block;
    text-align: center;
  }
}

.search-cause-wrap form span {
  color: #fff;
  line-height: 60px;
  font-weight: 500;
}

@media (max-width: 991px) {
  .search-cause-wrap form span {
    display: block;
    text-align: center;
    line-height: 40px;
  }
}

.search-cause-wrap form input {
  width: 69%;
  display: inline-block;
  height: 60px;
  background: transparent;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  font-weight: 500;
}

@media (max-width: 1191px) {
  .search-cause-wrap form input {
    width: 58%;
  }
}

@media (max-width: 991px) {
  .search-cause-wrap form input {
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 30px;
  }
}

.search-cause-wrap form input ::-webkit-input-placeholder {
  color: #fff;
}

.search-cause-wrap form input :-ms-input-placeholder {
  color: #fff;
}

.search-cause-wrap form input ::-ms-input-placeholder {
  color: #fff;
}

.search-cause-wrap form input ::placeholder {
  color: #fff;
}

.search-cause-wrap form .theme-btn {
  background-color: #d55342;
  border-color: #d55342;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.search-cause-wrap form .theme-btn i {
  margin-right: 5px;
}

.search-cause-wrap form .theme-btn:hover {
  background-color: #efc94c;
  border-color: #efc94c;
}

.faq-bg {
  background-color: #f0f0f0;
}

.faq-2 {
  background-size: 80%;
}

.faq-2 .faq-left-img {
  position: absolute;
  bottom: 0;
  content: "";
  margin-bottom: -120px;
  margin-left: -220px;
}

.faq-2 .faq-left-img::before {
  position: absolute;
  width: 531px;
  height: 371px;
  content: "";
  background-color: #cfac6e;
  top: 25%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1;
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.faq-2 .accordion .card {
  margin-top: 20px;
}

.faq-2 .accordion .card-header a {
  -webkit-box-shadow: 0px 80px 180px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 80px 180px 0px rgba(0, 0, 0, 0.06);
  color: #001248;
}

@media (max-width: 485px) {
  .faq-2 .accordion .card-header a {
    padding-right: 40px !important;
  }
}

.faq-2 .accordion .card-header a::after {
  background-color: #cfac6e;
  color: #001248;
  padding: 5px 20px;
}

.faq-section {
  padding-top: 90px;
}

@media (max-width: 991px) {
  .faq-section {
    padding-top: 70px;
  }
}

@media (max-width: 767px) {
  .faq-section {
    padding-top: 50px;
  }
}

.faq-section .accordion .card {
  margin-top: 30px;
  border: none;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  background-color: #fff;
}

.faq-section .accordion .card-header {
  padding: 0px 28px;
  border: none;
  background-color: #fff;
}

@media (max-width: 480px) {
  .faq-section .accordion .card-header {
    padding: 0 15px;
  }
}

.faq-section .accordion .card-header a {
  display: block;
  line-height: 50px;
  padding: 10px 0px;
  position: relative;
}

.faq-section .accordion .card-header a:after {
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media (max-width: 767px) {
  .faq-section .accordion .card-header a:after {
    right: -28px;
  }
}

@media (max-width: 500px) {
  .faq-section .accordion .card-header a:after {
    right: -10px;
  }
}

.faq-section .accordion .card-header a[aria-expanded="true"]:after {
  content: "\f068";
}

@media (max-width: 767px) {
  .faq-section .accordion .card-header a {
    line-height: 40px;
    font-size: 16px;
    padding: 6px 0px;
  }
}

.faq-section .accordion .card-header a:hover {
  color: #cfac6e;
}

.faq-section .accordion .card-body {
  padding-left: 28px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.single-blog-card {
  margin-top: 60px;
}

.single-blog-card:hover h3 {
  color: #febc35;
}

.single-blog-card .featured-img {
  width: 100%;
  height: 240px;
  background-color: #f6f6f6;
  border-radius: 10px 10px 0px 0px;
}

@media (max-width: 767px) {
  .single-blog-card .featured-img {
    height: 200px;
  }
}

.single-blog-card .featured-img a {
  display: block;
  width: 100%;
  overflow: hidden;
}

.single-blog-card .featured-img a img {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-blog-card .post-content {
  padding: 32px 40px;
  background-color: #fff;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-color: #e4e4e4;
  border-top: 0px;
  border-radius: 0px 0px 10px 10px;
}

@media (max-width: 991px) {
  .single-blog-card .post-content {
    padding: 30px 15px;
  }
}

@media (max-width: 767px) {
  .single-blog-card .post-content {
    padding: 30px;
  }
  .single-blog-card .post-content h3 {
    font-size: 20px;
  }
}

.single-blog-card .post-content .post-date {
  position: absolute;
  right: 40px;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #cfac6e;
  color: #000;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  padding: 16px 17px;
  padding-top: 12px;
  text-transform: capitalize;
}

.single-blog-card .post-content .post-date span {
  display: block;
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
}

@media (max-width: 767px) {
  .single-blog-card .post-content .post-date span {
    font-size: 30px;
  }
}

.single-blog-card .post-content .post-meta .post-cat {
  color: #777;
}

.single-blog-card .post-content .post-meta .post-cat, .single-blog-card .post-content .post-meta .post-author {
  font-size: 14px;
  line-height: 16px;
}

.single-blog-card .post-content .post-meta .post-author {
  font-weight: 700;
  color: #000;
}

.single-blog-card .post-content h3 {
  letter-spacing: -1px;
  line-height: 141%;
  margin-top: 3px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.single-blog-vcard {
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  padding: 315px 60px 60px;
  padding-right: 115px;
  width: 100%;
  z-index: 1;
}

@media (max-width: 991px) {
  .single-blog-vcard {
    padding: 200px 40px 40px;
  }
}

@media (max-width: 767px) {
  .single-blog-vcard {
    padding: 150px 30px 30px;
  }
}

.single-blog-vcard .post-content h3 {
  margin-top: 10px;
  font-size: 26px;
}

.single-blog-vcard .post-content h3 a {
  color: inherit !important;
}

.single-blog-vcard .post-content h3:hover {
  color: #ff5e14 !important;
}

.single-blog-vcard .post-content .post-meta a, .single-blog-vcard .post-content .post-meta span, .single-blog-vcard .post-content .post-meta i {
  font-size: 14px;
  line-height: 24px;
}

.single-blog-vcard .post-content .post-meta i {
  margin-right: 8px;
}

.single-news-card {
  border-style: solid;
  border-width: 2px;
  border-color: #f2f2f2;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 20px;
  padding-bottom: 30px;
  position: relative;
  margin-top: 50px;
}

.single-news-card .featured-thumb {
  height: 240px;
  background-color: #eee;
  position: relative;
  border-radius: 0px;
}

.single-news-card .post-content {
  padding: 0px 20px;
}

.single-news-card .post-content i {
  margin-right: 5px;
}

.single-news-card .post-content .post-meta {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.single-news-card .post-content .post-meta .post-author a {
  color: #001248;
  font-weight: 500;
  font-size: 14px;
}

.single-news-card .post-content h3 {
  font-size: 20px;
  line-height: 140%;
  color: #001659;
  font-weight: 700;
}

.single-news-card .post-content h3 a:hover {
  color: #cfac6e;
}

.single-news-card .post-content .read-btn {
  margin-top: 30px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #c3c3c3;
  text-transform: capitalize;
  text-decoration: underline;
}

.single-news-card .post-content .read-btn:hover {
  color: #cfac6e;
}

.single-news-card .post-cat {
  display: inline-block;
  padding: 10px 35px;
  color: #001248;
  background-color: #cfac6e;
  text-transform: capitalize;
  position: absolute;
  right: 20px;
  bottom: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  font-weight: 500;
  line-height: 1;
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  z-index: 0;
}

.single-news-card .post-cat:hover {
  background-color: #001659;
  color: #fff;
}

.blog-list-view .single-blog-item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.blog-list-view .single-blog-item:last-child {
  padding-bottom: 0px;
  border: none;
}

.blog-list-view .single-blog-item .featured-thumb {
  width: 120px;
  height: 120px;
  float: left;
  overflow: hidden;
  text-align: center;
  background-color: #e6e6e6;
  color: #fff;
  margin-right: 30px;
  padding-top: 50px;
}

@media (max-width: 500px) {
  .blog-list-view .single-blog-item .featured-thumb {
    width: 100%;
    height: 120px;
    float: none;
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.blog-list-view .single-blog-item .post-content {
  overflow: auto;
  margin-top: 5px;
}

.blog-list-view .single-blog-item .post-content h3 {
  font-size: 22px;
  letter-spacing: -1.1px;
  margin-top: 5px;
  line-height: 140%;
  color: #001659;
}

@media (max-width: 767px) {
  .blog-list-view .single-blog-item .post-content h3 {
    font-size: 20px;
  }
}

.blog-list-view .single-blog-item .post-content h3:hover {
  color: #f86048;
}

.blog-list-view .single-blog-item .post-content .post-meta {
  padding-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-list-view .single-blog-item .post-content .post-meta span, .blog-list-view .single-blog-item .post-content .post-meta i {
  font-size: 14px;
  line-height: 24px;
}

.blog-list-view .single-blog-item .post-content .post-meta .post-author {
  margin-right: 25px;
  position: relative;
  padding-right: 25px;
}

.blog-list-view .single-blog-item .post-content .post-meta .post-author::before {
  position: absolute;
  right: 0;
  content: "";
  width: 1px;
  height: 60%;
  background-color: #e6e6e6;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.blog-list-view .single-blog-item .post-content .post-meta i {
  margin-right: 5px;
}

.blog-list-view .single-blog-item .post-content .post-meta a {
  color: #001659;
  position: relative;
  font-size: 14px;
  padding-right: 5px;
}

.blog-list-view .single-blog-item .post-content .post-meta a::before {
  position: absolute;
  right: 0;
  content: ",";
}

.blog-list-view .single-blog-item .post-content .post-meta a:last-child::before {
  display: none;
}

.blog-wrapper .border-wrap {
  padding: 50px;
}

@media (max-width: 767px) {
  .blog-wrapper .border-wrap {
    padding: 50px 15px;
  }
}

.blog-wrapper .border-wrap .single-blog-post .post-content {
  padding: 0px;
}

.blog-wrapper .single-blog-post {
  margin-bottom: 40px;
  position: relative;
}

.blog-wrapper .single-blog-post.post-details .post-content {
  border: none;
}

.blog-wrapper .single-blog-post.post-details .post-content img {
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog-wrapper .single-blog-post.post-details .post-content h1 {
  font-size: 36px;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .blog-wrapper .single-blog-post.post-details .post-content h1 {
    font-size: 28px;
  }
  .blog-wrapper .single-blog-post.post-details .post-content h3 {
    font-size: 20px;
  }
}

.blog-wrapper .single-blog-post.post-details blockquote, .blog-wrapper .single-blog-post.post-details .wp-block-quote {
  background-color: #001659;
  color: #fff;
  font-size: 26px;
  line-height: 1.4;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 60px;
  text-align: left;
  margin: 40px 0px;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .blog-wrapper .single-blog-post.post-details blockquote, .blog-wrapper .single-blog-post.post-details .wp-block-quote {
    padding: 30px 15px;
    font-size: 18px;
    line-height: 1.5;
  }
}

.blog-wrapper .single-blog-post.post-details blockquote::before, .blog-wrapper .single-blog-post.post-details .wp-block-quote::before {
  right: 30px;
  font-size: 110px;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  content: "\f10d";
  bottom: -20px;
  color: #fff;
  z-index: -1;
  opacity: .1;
  font-weight: 900;
}

.blog-wrapper .single-blog-post.format-video .post-featured-thumb, .blog-wrapper .single-blog-post.category-video-post .post-featured-thumb {
  z-index: 1;
}

.blog-wrapper .single-blog-post.format-video .post-featured-thumb::before, .blog-wrapper .single-blog-post.category-video-post .post-featured-thumb::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  background-color: #282835;
  opacity: .3;
  z-index: -1;
}

.blog-wrapper .single-blog-post.quote-post .post-content, .blog-wrapper .single-blog-post.format-quote .post-content {
  position: relative;
  background-color: #ff5e14;
  background-image: url("../img/quotepost.png");
}

.blog-wrapper .single-blog-post.quote-post .quote-content, .blog-wrapper .single-blog-post.format-quote .quote-content {
  overflow: hidden;
}

@media (max-width: 585px) {
  .blog-wrapper .single-blog-post.quote-post .quote-content, .blog-wrapper .single-blog-post.format-quote .quote-content {
    text-align: center;
    font-size: 24px;
  }
}

.blog-wrapper .single-blog-post.quote-post .quote-content .icon, .blog-wrapper .single-blog-post.format-quote .quote-content .icon {
  font-size: 76px;
  line-height: 80px;
  float: left;
  overflow: hidden;
  margin-right: 30px;
}

@media (max-width: 585px) {
  .blog-wrapper .single-blog-post.quote-post .quote-content .icon, .blog-wrapper .single-blog-post.format-quote .quote-content .icon {
    float: none;
    margin-bottom: 10px;
    margin-right: 0;
    font-size: 50px;
    line-height: 60px;
  }
}

.blog-wrapper .single-blog-post.quote-post .quote-content .quote-text, .blog-wrapper .single-blog-post.format-quote .quote-content .quote-text {
  overflow: hidden;
}

.blog-wrapper .single-blog-post.quote-post .quote-content h2, .blog-wrapper .single-blog-post.format-quote .quote-content h2 {
  margin-top: -2px;
}

.blog-wrapper .single-blog-post:last-child {
  margin-bottom: 0;
}

.blog-wrapper .single-blog-post .post-featured-thumb {
  height: 380px;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
}

@media (max-width: 991px) {
  .blog-wrapper .single-blog-post .post-featured-thumb {
    height: 350px;
  }
}

@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-featured-thumb {
    height: 280px;
  }
}

.blog-wrapper .single-blog-post .post-featured-thumb .video-play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
}

.blog-wrapper .single-blog-post .post-featured-thumb .video-play-btn .play-video {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 18px;
}

.blog-wrapper .single-blog-post .post-content {
  padding: 50px;
  border: 2px solid #ededed;
  border-top: 0px;
  overflow: hidden;
}

.blog-wrapper .single-blog-post .post-content h2:hover a {
  color: #cfac6e;
}

@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content {
    padding: 50px 10px;
  }
  .blog-wrapper .single-blog-post .post-content h2 {
    font-size: 26px;
    line-height: 1.4;
  }
}

@media (max-width: 414px) {
  .blog-wrapper .single-blog-post .post-content h2 {
    font-size: 22px;
  }
}

.blog-wrapper .single-blog-post .post-content .post-cat a {
  color: #fff;
  display: inline-block;
  background-color: #ff5e14;
  padding: 9px 18px;
  line-height: 1;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-cat a {
    font-size: 12px;
  }
}

.blog-wrapper .single-blog-post .post-content .post-cat a:hover {
  background-color: #cfac6e;
}

.blog-wrapper .single-blog-post .post-content ul {
  margin-bottom: 20px;
  margin-top: 15px;
}

@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content ul {
    margin-left: 0;
  }
}

.blog-wrapper .single-blog-post .post-content ul li {
  font-size: 16px;
  color: #727475;
  font-weight: 700;
  margin-top: 10px;
  padding-left: 30px;
  display: block;
}

.blog-wrapper .single-blog-post .post-content ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  color: #222;
}

.blog-wrapper .single-blog-post .post-content p {
  margin-top: 15px;
}

.blog-wrapper .single-blog-post .post-content .post-meta {
  margin-top: 10px;
}

.blog-wrapper .single-blog-post .post-content .post-meta span {
  color: #202426;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  margin-right: 25px;
}

@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-meta span {
    font-size: 13px;
  }
}

.blog-wrapper .single-blog-post .post-content .post-meta span i {
  margin-right: 5px;
}

.blog-wrapper .single-blog-post .post-content .author-info {
  overflow: hidden;
}

.blog-wrapper .single-blog-post .post-content .author-info .author-img {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin-right: 15px;
  float: left;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .author-info .author-img {
    margin-right: 5px;
  }
}

.blog-wrapper .single-blog-post .post-content .author-info h5 {
  display: inline-block;
  line-height: 1;
  font-size: 16px;
  margin-top: 9px;
}

@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .author-info h5 {
    font-size: 14px;
  }
}

.blog-wrapper .single-blog-post .post-content .post-link a {
  font-weight: 700;
}

@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-link a {
    font-size: 14px;
  }
}

.blog-wrapper .single-blog-post .post-content .post-link a i {
  margin-right: 5px;
}

@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-link a i {
    margin-right: 3px;
  }
}

@media (min-width: 991px) {
  .main-sidebar {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .main-sidebar {
    margin-top: 40px;
  }
}

.main-sidebar .single-sidebar-widget {
  border: 2px solid #ededed;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px;
  margin-bottom: 40px;
}

.main-sidebar .single-sidebar-widget:last-child {
  margin-bottom: 0;
}

.main-sidebar .single-sidebar-widget .wid-title {
  margin-bottom: 35px;
  position: relative;
  padding-left: 45px;
}

.main-sidebar .single-sidebar-widget .wid-title::before {
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  content: "";
  height: 4px;
  width: 18px;
  border-radius: 5px;
  position: absolute;
  background-color: #ff5e14;
}

.main-sidebar .single-sidebar-widget .wid-title::after {
  left: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  content: "";
  height: 4px;
  width: 4px;
  border-radius: 50%;
  position: absolute;
  background-color: #ff5e14;
}

.main-sidebar .single-sidebar-widget .social-link a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: #f2f2f2;
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.main-sidebar .single-sidebar-widget .social-link a:hover {
  background-color: #ff5e14;
  color: #fff;
}

.main-sidebar .author-box-widegts .author-img {
  width: 140px;
  height: 140px;
  line-height: 140px;
  background-color: #f2f2f2;
  border-radius: 50%;
  margin: 0 auto;
  display: inline-block;
  margin-bottom: 15px;
}

.main-sidebar .author-box-widegts p {
  font-size: 14px;
  margin-top: 10px;
}

.main-sidebar .author-box-widegts .social-profile {
  margin-top: 15px;
}

.main-sidebar .author-box-widegts .social-profile a {
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.main-sidebar .author-box-widegts .social-profile a:hover {
  color: #ff5e14;
}

.search_widget form {
  width: 100%;
  position: relative;
}

.search_widget form input {
  background-color: #f8f8f8;
  font-size: 15px;
  padding: 20px;
  width: 100%;
}

.search_widget form button {
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  font-size: 18px;
  height: 100%;
  background-color: #ff5e14;
  color: #fff;
  text-align: center;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.search_widget form button:hover {
  background-color: #001659;
}

.instagram-gallery, .instagram_gallery {
  width: 100%;
  overflow: hidden;
  margin-left: -5px;
}

.instagram-gallery .single-photo-item, .instagram_gallery .single-photo-item {
  float: left;
  margin-left: 4%;
  background-color: #f8f8f8;
  height: 80px;
  width: 29%;
  overflow: hidden;
  margin-bottom: 10px;
}

.popular-posts .single-post-item, .popular_posts .single-post-item {
  overflow: hidden;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
}

.popular-posts .single-post-item:last-child, .popular_posts .single-post-item:last-child {
  margin-bottom: 0;
  border: none;
  padding-bottom: 0;
}

.popular-posts .single-post-item .thumb, .popular_posts .single-post-item .thumb {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f2f2f2;
  float: left;
  overflow: hidden;
  margin-right: 20px;
}

.popular-posts .single-post-item .post-content, .popular_posts .single-post-item .post-content {
  overflow: hidden;
}

.popular-posts .single-post-item .post-content h5, .popular_posts .single-post-item .post-content h5 {
  font-size: 16px;
  line-height: 22px;
}

.popular-posts .single-post-item .post-content h5:hover, .popular_posts .single-post-item .post-content h5:hover {
  color: #ff5e14;
}

.popular-posts .single-post-item .post-content .post-date, .popular_posts .single-post-item .post-content .post-date {
  margin-top: 5px;
  font-weight: 700;
  font-size: 14px;
}

.popular-posts .single-post-item .post-content .post-date i, .popular_posts .single-post-item .post-content .post-date i {
  margin-right: 7px;
}

.tagcloud a {
  display: inline-block;
  padding: 11px 26px;
  line-height: 1;
  font-size: 14px;
  font-weight: 700;
  background: #f8f8f8;
  margin-right: 5px;
  margin-bottom: 8px;
}

.tagcloud a:last-child {
  margin-right: 0;
}

.tagcloud a:hover {
  background-color: #ff5e14;
  color: #fff;
}

.widget_categories ul li {
  display: block;
}

.widget_categories ul li:last-child a {
  margin-bottom: 0;
}

.widget_categories ul li a {
  position: relative;
  background: #f8f8f8;
  padding: 20px 30px;
  line-height: 1;
  font-size: 14px;
  font-weight: 700;
  display: block;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  margin-bottom: 10px;
}

.widget_categories ul li a:hover {
  color: #ff5e14;
}

.widget_categories ul li a span {
  position: absolute;
  width: 60px;
  line-height: 53px;
  height: 100%;
  content: "";
  background-color: #e3e3e3;
  right: 0;
  top: 0;
  text-align: center;
}

.causes-page-nav ul li, .page-nav-wrap ul li {
  display: inline-block;
}

.causes-page-nav ul li a, .page-nav-wrap ul li a {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #f6f6f6;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 0 2px;
}

@media (max-width: 767px) {
  .causes-page-nav ul li a, .page-nav-wrap ul li a {
    margin-top: 10px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}

.causes-page-nav ul li a i, .page-nav-wrap ul li a i {
  margin-top: 2px;
}

.causes-page-nav ul li a:hover, .page-nav-wrap ul li a:hover {
  background-color: #ff5e14;
  color: #fff;
}

.social-share a {
  margin-left: 10px;
}

.tag-share-wrap {
  padding-bottom: 50px;
}

.tag-share-wrap h4 {
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .tag-share-wrap h4 {
    margin-bottom: 10px;
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .tag-share-wrap .tagcloud {
    margin-bottom: 20px;
  }
}

.related-post-wrap, .related_posts_wrap {
  border-top: 1px solid #e2e2e2;
  padding-top: 40px;
}

.related-post-wrap .single-related-post, .related_posts_wrap .single-related-post {
  margin-top: 33px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
          box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
}

.related-post-wrap .single-related-post:hover h4, .related_posts_wrap .single-related-post:hover h4 {
  color: #ff5e14;
}

.related-post-wrap .single-related-post .featured-thumb, .related_posts_wrap .single-related-post .featured-thumb {
  height: 280px;
  background-color: #f8f8f8;
}

@media (max-width: 767px) {
  .related-post-wrap .single-related-post .featured-thumb, .related_posts_wrap .single-related-post .featured-thumb {
    height: 200px;
  }
}

.related-post-wrap .single-related-post .post-content, .related_posts_wrap .single-related-post .post-content {
  padding: 35px;
}

@media (max-width: 767px) {
  .related-post-wrap .single-related-post .post-content, .related_posts_wrap .single-related-post .post-content {
    padding: 30px 15px;
  }
}

.related-post-wrap .single-related-post .post-content .post-date, .related_posts_wrap .single-related-post .post-content .post-date {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #202426;
}

.related-post-wrap .single-related-post .post-content .post-date i, .related_posts_wrap .single-related-post .post-content .post-date i {
  margin-right: 6px;
}

.related-post-wrap .single-related-post .post-content h4, .related_posts_wrap .single-related-post .post-content h4 {
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .related-post-wrap .single-related-post .post-content h4, .related_posts_wrap .single-related-post .post-content h4 {
    font-size: 18px;
  }
}

.related-post-wrap .single-related-post .post-content p, .related_posts_wrap .single-related-post .post-content p {
  font-size: 15px;
  line-height: 26px;
  color: #727475;
}

.comments-section-wrap {
  overflow: hidden;
}

.comments-heading h3, .related-post-wrap h3, .comment-form-wrap h3 {
  font-size: 26px;
}

@media (max-width: 767px) {
  .comments-heading h3, .related-post-wrap h3, .comment-form-wrap h3 {
    font-size: 20px;
  }
}

.comments-item-list .replay-comment {
  margin-left: 100px;
}

@media (max-width: 767px) {
  .comments-item-list .replay-comment {
    margin-left: 15px;
  }
}

.comments-item-list .single-comment-item {
  margin-top: 30px;
}

.comments-item-list .single-comment-item .author-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  float: left;
  overflow: hidden;
  margin-right: 30px;
}

@media (max-width: 585px) {
  .comments-item-list .single-comment-item .author-img {
    float: none;
    margin-bottom: 20px;
  }
}

.comments-item-list .single-comment-item .author-info-comment {
  overflow: auto;
  padding-bottom: 25px;
  border-bottom: 1px solid #e2e2e2;
}

.comments-item-list .single-comment-item .author-info-comment .info {
  position: relative;
}

@media (max-width: 767px) {
  .comments-item-list .single-comment-item .author-info-comment .info h5 {
    font-size: 16px;
  }
}

.comments-item-list .single-comment-item .author-info-comment span {
  color: #ff5e14;
}

.comments-item-list .single-comment-item .author-info-comment .theme-btn {
  padding: 10px 18px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #000;
}

@media (max-width: 767px) {
  .comments-item-list .single-comment-item .author-info-comment .theme-btn {
    font-size: 12px;
  }
}

.comments-item-list .single-comment-item .author-info-comment .theme-btn i {
  margin-right: 5px;
  margin-left: 0;
}

.comments-item-list .single-comment-item .author-info-comment .comment-text {
  margin-top: 15px;
}

.comment-form {
  background-color: #f0f0f0;
  padding: 50px;
  width: 100%;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .comment-form {
    padding: 30px;
  }
}

@media (max-width: 585px) {
  .comment-form {
    padding: 30px 15px;
  }
}

.comment-form input, .comment-form textarea {
  margin-bottom: 20px;
  padding: 24px 30px;
  line-height: 1;
  font-size: 14px;
  width: 100%;
}

@media (max-width: 585px) {
  .comment-form input, .comment-form textarea {
    padding: 15px;
  }
}

.comment-form textarea {
  height: 200px;
}

@media (max-width: 767px) {
  .comment-form textarea {
    height: 120px;
  }
}

.comment-form .submit-btn {
  display: inline-block;
  background-color: #ff5e14;
  color: #fff;
  line-height: 1;
  font-size: 700;
  text-transform: capitalize;
  padding: 24px 60px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media (max-width: 767px) {
  .comment-form .submit-btn {
    padding: 20px 40px;
  }
}

@media (max-width: 585px) {
  .comment-form .submit-btn {
    padding: 15px 40px;
    font-size: 14px;
  }
}

.comment-form .submit-btn:hover {
  background-color: #001659;
}

.comment-form .submit-btn i {
  margin-right: 10px;
}

.border-wrap {
  border: 2px solid #ededed;
}

/* ----------------------------------
	Contact Us Section Styles
 ------------------------------------ */
.contact-form {
  padding-top: 40px;
}

@media (max-width: 585px) {
  .contact-form {
    padding-top: 20px;
  }
}

.contact-form .single-personal-info {
  margin-bottom: 20px;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  background: #f8f8f8;
  line-height: 1;
  padding: 25px 30px;
  border: 0px;
}

@media (max-width: 585px) {
  .contact-form input, .contact-form textarea {
    padding: 15px;
    font-size: 13px;
  }
}

.contact-form textarea {
  height: 220px;
}

@media (max-width: 767px) {
  .contact-form textarea {
    height: 160px;
  }
}

.contact-form label {
  color: #202426;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.contact-form label span {
  color: #727475;
  margin-left: 5px;
}

.contact-form input[type='submit'], .contact-form .submit-btn {
  background: #cfac6e;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  padding: 25px 40px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  width: 280px;
}

.contact-form input[type='submit'].active, .contact-form input[type='submit']:hover, .contact-form .submit-btn.active, .contact-form .submit-btn:hover {
  background-color: #001659;
}

.contact-form input[type='submit'] i, .contact-form .submit-btn i {
  margin-right: 8px;
}

@media (max-width: 400px) {
  .contact-form input[type='submit'], .contact-form .submit-btn {
    width: 100%;
  }
}

.single-contact-card {
  background-color: #f8f8f8;
  padding: 40px;
  margin-bottom: 30px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media (max-width: 585px) {
  .single-contact-card {
    padding: 30px;
  }
}

.single-contact-card:hover {
  background-color: #202426;
}

.single-contact-card:hover .icon {
  color: #fff !important;
  background-color: #cfac6e !important;
}

.single-contact-card:hover .bottom-part .icon {
  color: #cfac6e !important;
  background-color: #fff !important;
}

.single-contact-card:hover p, .single-contact-card:hover span {
  color: #c5c7c8;
}

.single-contact-card:hover h4 {
  color: #fff;
}

.single-contact-card .top-part {
  overflow: hidden;
}

.single-contact-card .top-part .icon {
  width: 70px;
  height: 70px;
  font-size: 20px;
  line-height: 70px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  float: left;
  color: #cfac6e;
  overflow: hidden;
  margin-right: 20px;
}

.single-contact-card .top-part .title {
  overflow: hidden;
}

.single-contact-card .bottom-part {
  margin-top: 25px;
  overflow: hidden;
}

.single-contact-card .bottom-part .icon {
  width: 50px;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  float: right;
  overflow: hidden;
  margin-left: 20px;
}

.single-contact-card .bottom-part .info {
  display: inline-block;
  overflow: hidden;
}

.contact-map-wrap iframe {
  height: 450px;
}

@media (max-width: 991px) {
  .contact-map-wrap iframe {
    height: 300px;
  }
}

.make-appointment-wrapper {
  background-color: #001659;
}

.footer-1 {
  position: relative;
}

.footer-1 .footer-widgets {
  position: relative;
  z-index: 1;
}

.footer-1 .footer-bottom {
  background-color: #febc35;
  z-index: 1;
}

@media (max-width: 767px) {
  .footer-1 .footer-bottom {
    text-align: center;
  }
  .footer-1 .footer-bottom .scroll-up-btn {
    display: none;
  }
}

.footer-1 .footer-logo a {
  position: absolute;
  width: 310px;
  background-color: #fff;
  height: 120px;
  line-height: 120px;
  text-align: center;
  display: inline-block;
  top: -73px;
  z-index: 22;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media (max-width: 991px) {
  .footer-1 .footer-logo a {
    position: inherit;
    -webkit-transform: inherit;
            transform: inherit;
    top: inherit;
    height: 100px;
    line-height: 100px;
    margin-top: 20px;
  }
  .footer-1 .footer-logo a::before, .footer-1 .footer-logo a::after {
    display: none;
  }
}

.footer-1 .footer-logo a::before {
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 26px solid #ed9f00;
  border-left: 26px solid transparent;
  content: "";
  right: 100%;
  top: 0;
  z-index: -1;
}

.footer-1 .footer-logo a::after {
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 26px solid #ed9f00;
  border-right: 26px solid transparent;
  content: "";
  left: 100%;
  top: 0;
  z-index: -1;
}

.footer-widgets {
  padding-top: 55px;
  padding-bottom: 100px;
}

@media (max-width: 991px) {
  .footer-widgets {
    padding-bottom: 80px;
  }
}

.footer-2 {
  background: #001659;
}

.footer-2 h3, .footer-2 h5, .footer-2 h4 {
  color: #fff;
}

.footer-2 .footer-widgets {
  padding-bottom: 70px;
}

.footer-2 .footer-widgets ul li a {
  color: #b7bccb !important;
}

.footer-2 .footer-widgets ul li a:hover {
  color: #ff5e14 !important;
}

.footer-2 .footer-widgets .site_info_widget .single-contact-info {
  border-color: #1b2d60 !important;
}

.footer-2 .footer-widgets .site_info_widget .single-contact-info .icon {
  border-radius: 50px !important;
  background-color: #ff5e14 !important;
  color: #fff !important;
}

.footer-2 .footer-widgets .newsletter_widget p {
  color: #cacaca;
}

.footer-2 .footer-widgets .newsletter_widget .newsletter_box button {
  width: 60px;
  height: 60px;
  background: #ff5e14;
  color: #fff;
  display: inline-block;
  border-radius: 50px;
  position: absolute;
  right: 0;
}

.footer-2 .footer-widgets .newsletter_widget .newsletter_box input {
  border-right-width: 0px;
  border-color: #1b2d60;
  border-radius: 50px;
  background: #1b2d60;
  width: 100%;
  height: 60px;
}

.footer-2 .footer-bottom {
  background-color: #000b2c;
}

.footer-2 .footer-bottom .copyright-info a {
  color: #ff5e14 !important;
  text-transform: capitalize;
}

.footer-wrap .footer-bottom {
  padding: 23px 0px;
}

.footer-wrap .footer-bottom p, .footer-wrap .footer-bottom a {
  color: #000;
}

.footer-wrap .footer-bottom a {
  font-weight: 500;
}

.footer-wrap .single-footer-wid {
  margin-top: 40px;
}

.footer-wrap .single-footer-wid .wid-title {
  margin-bottom: 40px;
}

.footer-wrap .single-footer-wid.site_info_box {
  text-align: center;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: relative;
  margin-right: 40px;
  padding: 60px 40px;
  margin-top: -80px;
  z-index: 9;
}

.footer-wrap .single-footer-wid.site_info_box .f_logo {
  margin-bottom: 30px;
  display: block;
}

.footer-wrap .single-footer-wid.site_info_box::before {
  position: absolute;
  width: 100%;
  height: 6px;
  background-color: #ff5e14;
  content: "";
  top: 0;
  left: 0;
}

.footer-wrap .single-footer-wid.site_info_box p {
  color: #777;
  font-size: 14px;
}

.footer-wrap .single-footer-wid.site_info_box .social_link {
  margin-top: 40px;
}

@media (max-width: 767px) {
  .footer-wrap .single-footer-wid.site_info_box .social_link {
    margin-top: 30px;
  }
}

.footer-wrap .single-footer-wid.site_info_box .social_link a {
  background-color: #f6f6f6;
  width: 40px;
  height: 40px;
  line-height: 41px;
  border-radius: 50px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
  display: inline-block;
  color: #001659;
  margin-right: 5px;
}

.footer-wrap .single-footer-wid.site_info_box .social_link a:hover {
  color: #fff;
  background-color: #ff5e14;
}

.footer-wrap .single-footer-wid.site_info_widget .single-contact-info {
  overflow: auto;
  margin-bottom: 20px;
  border-bottom: 1px solid #282828;
  padding-bottom: 25px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-wrap .single-footer-wid.site_info_widget .single-contact-info:last-child {
  border: 0;
  margin-bottom: 0;
}

.footer-wrap .single-footer-wid.site_info_widget .single-contact-info:hover .icon {
  background-color: #fff;
  color: #cfac6e;
}

.footer-wrap .single-footer-wid.site_info_widget .single-contact-info p {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.footer-wrap .single-footer-wid.site_info_widget .single-contact-info span {
  display: block;
  color: #cacaca;
  font-size: 14px;
}

.footer-wrap .single-footer-wid.site_info_widget .single-contact-info .icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #000;
  background-color: #cfac6e;
  float: left;
  text-align: center;
  overflow: hidden;
  font-size: 16px;
  margin-right: 20px;
  border-radius: 10px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.footer-wrap .single-footer-wid .social_link {
  margin-top: 40px;
}

@media (max-width: 767px) {
  .footer-wrap .single-footer-wid .social_link {
    margin-top: 30px;
  }
}

.footer-wrap .single-footer-wid .social_link a {
  background-color: #0f0f0f;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 10px;
  font-size: 16px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
  display: inline-block;
  color: #fff;
  margin-right: 5px;
}

.footer-wrap .single-footer-wid .social_link a:hover {
  color: #fff;
  background-color: #cfac6e;
}

.footer-wrap .single-footer-wid ul li > a {
  color: #fff;
  margin-bottom: 15px;
  display: block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500;
  text-transform: capitalize;
}

@media (max-width: 1191px) {
  .footer-wrap .single-footer-wid ul li > a {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .footer-wrap .single-footer-wid ul li > a {
    margin-bottom: 15px;
  }
}

.footer-wrap .single-footer-wid ul li > a:hover {
  color: #cfac6e;
  padding-left: 10px;
}

.footer-wrap .single-footer-wid ul li:last-child a {
  margin-bottom: 0;
}

.footer-3 {
  overflow: hidden;
  background-color: #f9f9f9;
}

@media (max-width: 991px) {
  .footer-3 .footer-widgets {
    padding-top: 35px;
  }
}

.footer-3 .single-footer-wid h3, .footer-3 .single-footer-wid h4 {
  color: #001248;
  font-weight: 700;
}

.footer-3 .single-footer-wid ul li {
  display: block;
  margin-bottom: 12px;
}

.footer-3 .single-footer-wid ul li:last-child {
  margin-bottom: 0;
}

.footer-3 .single-footer-wid ul li a {
  display: block;
  font-size: 15px;
  line-height: 30px;
  color: #777;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.footer-3 .single-footer-wid ul li a:hover {
  color: #001248;
  padding-left: 8px;
}

.footer-3 .footer-bottom {
  background: #eee;
}

@media (max-width: 767px) {
  .footer-3 .footer-bottom {
    text-align: center;
  }
}

.footer-3 .footer-bottom .social_link a {
  color: #999;
  margin-left: 15px;
}

.footer-3 .footer-bottom .social_link a:hover {
  color: #001248;
}

.footer-3 .footer-bottom .footer-logo {
  position: relative;
  max-width: 300px;
  width: 100%;
  z-index: 0;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .footer-3 .footer-bottom .footer-logo {
    margin-bottom: 30px;
  }
}

.footer-3 .footer-bottom .footer-logo::before {
  position: absolute;
  height: 300%;
  z-index: -1;
  content: "";
  background-color: #fff;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-28%);
          transform: translateY(-28%);
}

@media (max-width: 767px) {
  .footer-3 .footer-bottom .footer-logo::before {
    height: 110px;
    -webkit-transform: translateY(-35%);
            transform: translateY(-35%);
  }
}

.footer-3 .newsletter_widget .newsletter_box form {
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
}

.footer-3 .newsletter_widget .newsletter_box form input {
  border-radius: 0px;
  background: transparent;
  border: 0px;
  color: #001248;
}

.footer-3 .newsletter_widget .newsletter_box form button {
  border-radius: 0px;
}

.footer-3 .copyright-info a {
  text-transform: capitalize;
}

.footer-3 .copyright-info a:hover {
  color: #001248;
}

@media (max-width: 991px) {
  .footer-menu {
    margin-top: 10px;
  }
}

.footer-menu ul li {
  display: inline-block;
}

.footer-menu ul li a {
  display: inline-block;
  margin-left: 40px;
}

@media (max-width: 991px) {
  .footer-menu ul li a {
    margin: 0px 10px;
  }
}

@media (max-width: 767px) {
  .footer-menu ul li a {
    margin: 0px 5px;
  }
}

.recent_post_widget .recent-post-list .single-recent-post {
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 15px;
}

.recent_post_widget .recent-post-list .single-recent-post:last-child {
  margin-bottom: 0;
}

.recent_post_widget .recent-post-list .single-recent-post .thumb {
  background-color: #eee;
  width: 80px;
  height: 80px;
  overflow: hidden;
  float: left;
  margin-right: 20px;
  border-radius: 10px;
}

.recent_post_widget .recent-post-list .single-recent-post .post-data {
  margin-top: -5px;
}

.recent_post_widget .recent-post-list .single-recent-post .post-data span {
  font-size: 12px;
  line-height: 1;
}

.recent_post_widget .recent-post-list .single-recent-post .post-data span i {
  margin-right: 5px;
}

.recent_post_widget .recent-post-list .single-recent-post .post-data h5 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
}

.recent_post_widget .recent-post-list .single-recent-post .post-data h5:hover {
  color: #cfac6e;
}

@media (max-width: 991px) {
  .recent_post_widget .recent-post-list .single-recent-post .post-data h5 {
    font-size: 15px;
  }
}

.newsletter_widget .newsletter_box form {
  height: 64px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  margin-bottom: 10px;
}

.newsletter_widget .newsletter_box form input {
  border: 0px;
  width: 80%;
  position: relative;
  border-style: solid;
  border-width: 2px;
  border-right-width: 0px;
  border-color: #3a3a3a;
  border-radius: 10px 0px 0px 10px;
  background: #0a0a0a;
  padding-left: 25px;
  padding-right: 15px;
  line-height: 1;
  color: #fff;
}

.newsletter_widget .newsletter_box form input::-webkit-input-placeholder {
  color: #999;
}

.newsletter_widget .newsletter_box form input:-ms-input-placeholder {
  color: #999;
}

.newsletter_widget .newsletter_box form input::-ms-input-placeholder {
  color: #999;
}

.newsletter_widget .newsletter_box form input::placeholder {
  color: #999;
}

.newsletter_widget .newsletter_box form button {
  width: 20%;
  height: 100%;
  background: #cfac6e;
  color: #000;
  display: inline-block;
  border-radius: 0px 10px 10px 0px;
  font-size: 18px;
  line-height: 1;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.newsletter_widget .newsletter_box form button:hover {
  color: #000;
}

.newsletter_widget .newsletter_box span {
  color: #cacaca;
}

.contact_widget_2 .contact-us .single-contact-info {
  overflow: hidden;
  margin-bottom: 20px;
}

.contact_widget_2 .contact-us .single-contact-info:last-child {
  margin-bottom: 0;
}

.contact_widget_2 .contact-us .single-contact-info .icon {
  float: left;
  margin-right: 15px;
  overflow: hidden;
}

.contact_widget_2 .contact-us .single-contact-info .contact-info {
  overflow: hidden;
}

.contact_widget_2 .contact-us .single-contact-info .contact-info p {
  font-weight: 600;
  color: #001248;
  font-size: 17px;
}
